import React from "react";
import { Flex, Spin } from "antd";

export default function Loading({ size }) {
  return (
    <Flex
      justify="center"
      align="center"
      size="large"
      style={{ height: "100%", width: "100%" }}
    >
      <Spin size={size} />
    </Flex>
  );
}
