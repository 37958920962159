import React from "react";
import ReactDOM from "react-dom/client";
import AppProviders from "./app/AppProviders";
import { App } from "antd";
import "./index.scss";
import "@xyflow/react/dist/style.css";

import { AllCommunityModule, ModuleRegistry } from "ag-grid-community";

// Register all Community features
ModuleRegistry.registerModules([AllCommunityModule]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App>
      <AppProviders />
    </App>
  </React.StrictMode>
);
