import React, { useEffect } from "react";
import { Form, Input, Flex, Button, Card, Typography } from "antd";
import { useGetUserDetails, usePutUserDetails } from "../../../../apis/useUserApi";

const { Title } = Typography;

export default function ProfileForm() {
  const [form] = Form.useForm();
  const { data: userDetails } = useGetUserDetails();
  const putUserDetails = usePutUserDetails();

  useEffect(() => {
    if (userDetails) {
      form.setFieldsValue({
        firstName: userDetails.firstName,
        lastName: userDetails.lastName,
        email: userDetails.email,
        phone: userDetails.phone,
      });
    }
  }, [userDetails, form]);

  const onSubmit = (body) => {
    putUserDetails({ body });
  };

  return (
    <Flex
      vertical
      gap={24}
      style={{ margin: "auto", padding: "20px" }}
    >
      {/* 🔹 Profile Settings Card */}
      <Card
        bordered
        style={{ borderRadius: "2px" }}
      >
        <Title
          level={4}
          style={{ marginBottom: 12 }}
        >
          Profile Settings
        </Title>
        <Form
          name="profile-form"
          layout="vertical"
          form={form}
          onFinish={onSubmit}
        >
          <Flex
            gap={12}
            wrap="wrap"
          >
            <Form.Item
              label="First Name"
              name="firstName"
              rules={[{ required: true }]}
              style={{ flex: 1 }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Last Name"
              name="lastName"
              rules={[{ required: true }]}
              style={{ flex: 1 }}
            >
              <Input />
            </Form.Item>
          </Flex>

          <Flex
            gap={12}
            wrap="wrap"
          >
            <Form.Item
              label="Email"
              name="email"
              type="email"
              rules={[{ required: true }]}
              style={{ flex: 1, minWidth: "45%" }}
            >
              <Input
                disabled
                placeholder="Your email"
              />
            </Form.Item>
            <Form.Item
              label="Phone"
              name="phone"
              style={{ flex: 1 }}
            >
              <Input />
            </Form.Item>
          </Flex>

          <Flex justify="end">
            <Button
              type="primary"
              onClick={form.submit}
            >
              Save Changes
            </Button>
          </Flex>
        </Form>
      </Card>
    </Flex>
  );
}
