import React from "react";
import { Form, Input, Modal } from "antd";
import useWorkflowActions from "../../../../context/actions/useWorkflowActions";

export default function WorkflowForm({ workflow, open, setOpen }) {
  const { onCreateWorkflow, onUpdateWorkflow } = useWorkflowActions();
  const [form] = Form.useForm();
  const onSubmit = (values) => {
    if (workflow?.id) {
      onUpdateWorkflow({
        ...workflow,
        ...values,
      });
    } else {
      onCreateWorkflow(values);
    }
    setOpen(false);
  };

  React.useEffect(() => {
    if (workflow) {
      form.setFieldsValue(workflow);
    }
  }, [workflow, form]);

  return (
    <Modal
      title={workflow?.id ? "Update engine" : "Create Engine"}
      open={open}
      onCancel={() => setOpen(false)}
      okText={workflow?.id ? "Save" : "Create"}
      onOk={form.submit}
      width={480}
      // initialValues={workflow}
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={onSubmit}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input.TextArea autoSize={true} />
        </Form.Item>
      </Form>
    </Modal>
  );
}
