import React from "react";
import { Button, Divider, Grid, Space, theme, Typography } from "antd";

import "./PageTitle.scss";

const { useToken } = theme;
const { useBreakpoint } = Grid;
const { Title, Text } = Typography;

export default function PageTitle({ title, subtitle, actions }) {
  const { token } = useToken();
  const screens = useBreakpoint();

  const styles = {
    container: {
      margin: "0 auto",
      maxWidth: screens.lg ? token.screenXL : token.screenSM,
      padding: screens.md ? `0px ${token.paddingLG}px` : `0px ${token.padding}px`,
    },
    divider: {
      margin: 0,
    },
    header: {
      backgroundColor: token.colorBgContainer,
      padding: `${token.paddingLG}px 0px`,
    },
    placeholder: {
      backgroundColor: token.colorBgLayout,
      border: `${token.lineWidth}px dashed ${token.colorBorder}`,
      borderRadius: token.borderRadiusLG,
      padding: token.paddingLG,
      textAlign: "center",
    },
    tagline: {
      color: token.colorTextSecondary,
    },
    title: {
      fontSize: screens.md ? token.fontSizeHeading2 : token.fontSizeHeading3,
      margin: "0px",
    },
    titleWrapper: {
      alignItems: screens.md ? "flex-end" : "flex-start",
      justifyContent: "space-between",
      width: "100%",
    },
  };

  return (
    <>
      <div style={styles.header}>
        <div style={styles.container}>
          <Space
            size="middle"
            direction={screens.md ? "horizontal" : "vertical"}
            style={styles.titleWrapper}
          >
            <Space direction="vertical">
              {subtitle && <Text style={styles.tagline}>{subtitle}</Text>}
              <Title style={styles.title}>{title}</Title>
            </Space>
            {actions}
          </Space>
        </div>
      </div>
      <Divider style={styles.divider} />
    </>
  );
}
