import { createWithEqualityFn } from "zustand/traditional";
import { applyNodeChanges, applyEdgeChanges, MarkerType } from "@xyflow/react";
import { OPEN_WORKFLOW_LOGS, OPEN_WORKFLOW_PANEL } from "../../constants/views";
import { putTenantWorkflow } from "../../apis/useWorkflowApi";
import { message } from "antd";
import { kebabCase } from "lodash";
import { v4 } from "uuid";

const initWorkflowTitle = "(Untitled)";

const init = {
  nodes: [],
  edges: [],
  openPanel: null,
  openLogs: localStorage.getItem(OPEN_WORKFLOW_LOGS) === "true" ? true : false,
  selectedWorkflow: null,
  editAgent: null,
  workflowTitle: initWorkflowTitle,
  openAgentModal: false,
};

function isStartingNode(nodes, edges, nodeId) {
  try {
    // Find the node with the given ID
    const node = nodes.find((n) => n.id === nodeId);
    if (!node) return false; // Return false if the node is not found

    // Check if the node has any incoming edges
    const hasIncomingEdges = edges.some((edge) => edge.target === nodeId);

    // Return true if both conditions for starting node are met
    return !hasIncomingEdges;
  } catch (error) {
    console.error("Error processing the React Flow configuration:", error.message);
    return false;
  }
}

const colors = {
  edgeColor: "#555",
  edgeColorActive: "#e74c3c",
};

const useWorkflowStore = createWithEqualityFn((set, get) => {
  return {
    ...init,
    setNodes: (nodes) => set({ nodes }),
    setEdges: (edges) => set({ edges }),
    onNodesChange(changes) {
      set({
        nodes: applyNodeChanges(changes, get().nodes),
      });
    },

    onEdgesChange(changes) {
      const ogNodes = get().nodes;
      const edges = applyEdgeChanges(changes, get().edges);

      const nodes = [
        ...get().nodes.map((node) => {
          return {
            ...node,
            type: isStartingNode(ogNodes, edges, node.id) ? "StartingNode" : "GeneralNode",
          };
        }),
      ];

      set({
        edges,
        nodes,
      });

      putTenantWorkflow(get().selectedWorkflow.id, {
        name: get().selectedWorkflow.name,
        description: "Test",
        type: "Sequential",
        config: JSON.stringify({
          nodes,
          edges, // Include edges in the config
        }),
        isPrivate: true,
      });
    },

    addEdge(data) {
      const id = `${kebabCase(data.id)}:${v4().split("-").pop()}`;
      const edge = {
        id,
        ...data,
        style: {
          stroke: colors.edgeColor,
          strokeWidth: 2,
        },
        markerEnd: {
          type: MarkerType.Arrow,
          width: 14,
          height: 14,
          color: colors.edgeColor,
        },
      };
      const edges = [edge, ...get().edges];
      const ogNodes = get().nodes;
      const nodes = [
        ...get().nodes.map((node) => {
          return {
            ...node,
            type: isStartingNode(ogNodes, edges, node.id) ? "StartingNode" : "GeneralNode",
          };
        }),
      ];

      set({
        edges,
        nodes,
      });

      putTenantWorkflow(get().selectedWorkflow.id, {
        name: get().selectedWorkflow.name,
        description: "Test",
        type: "Sequential",
        config: JSON.stringify({
          nodes,
          edges, // Include edges in the config
        }),
        isPrivate: true,
      }).then((res) => {
        message.success(`Connection updated: ${data.source} → ${data.target}`);
      });
    },
    updateNode(id, data) {
      set({
        nodes: get().nodes.map((node) => (node.id === id ? { ...node, data: { ...node.data, ...data } } : node)),
      });
    },
    setOpenLogs: (openLogs) => {
      localStorage.setItem(OPEN_WORKFLOW_LOGS, openLogs);
      return set({ openLogs });
    },
    setOpenPanel: (openPanel) => {
      localStorage.setItem(OPEN_WORKFLOW_PANEL, openPanel);
      return set({ openPanel });
    },
    setSelectedWorkflow: (selectedWorkflow) => {
      set({
        selectedWorkflow,
        workflowTitle: selectedWorkflow?.name || initWorkflowTitle,
      });
    },
    setEditAgent: (editAgent) => set({ editAgent }),
    setWorkflowTitle: (workflowTitle) => set({ workflowTitle }),
    setOpenAgentModal: (openAgentModal) => set({ openAgentModal }),
    onEdgeClick: (event, edge) => {
      event.preventDefault();
      set({
        edges: get().edges.map((e) =>
          e.id === edge.id
            ? {
                ...e,
                style: {
                  ...e.style,
                  stroke: e.style.stroke === colors.edgeColorActive ? colors.edgeColor : colors.edgeColorActive,
                  strokeWidth: e.style.strokeWidth === 3 ? 2 : 3,
                },
                markerEnd: {
                  ...e.markerEnd,
                  color: e.markerEnd.color === colors.edgeColorActive ? colors.edgeColor : colors.edgeColorActive,
                },
              }
            : e
        ),
      });
    },
    onNodeClick: (event, node) => {
      event.preventDefault();
      set({
        nodes: get().nodes.map((n) =>
          n.id === node.id
            ? {
                ...n,
                data: {
                  ...n.data,
                  selected: !n.data.selected,
                },
              }
            : {
                ...n,
                data: {
                  ...n.data,
                  selected: false,
                },
              }
        ),
      });
    },
  };
});

export default useWorkflowStore;
