import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { ACCESS_TOKEN_KEY, APP_STATE } from "../../constants/auth";
import useCookies from "./useCookies";
import useAppStore from "../../context/stores/useAppStore";

const useGetApi = ({ url, queryKey, queryOptions = {}, params = {} }) => {
  const { getCookie, deleteCookie } = useCookies();
  const accessToken = getCookie(ACCESS_TOKEN_KEY);
  const { appState, setAppState } = useAppStore();

  const getApi = async () => {
    try {
      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params,
      });

      return res.data;
    } catch (error) {
      if (error.response) {
        const { status } = error.response;

        if (status === 401) {
          console.warn("Session expired. Logging out...");
          deleteCookie(ACCESS_TOKEN_KEY);
          setAppState(APP_STATE.unauthenticated);
        }
      }
      return error.response;
    }
  };

  // all useQuery return values
  return useQuery({
    ...queryOptions,
    queryKey,
    queryFn: getApi,
    enabled: appState === APP_STATE.authenticated,
    onError: (error) => {
      console.log({ error });
      return error;
    },
  });
};

export default useGetApi;
