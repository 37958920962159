import React, { useEffect } from "react";
import { Form, Input, Flex, Button, Divider, InputNumber, Card, Typography, Select } from "antd";
import { usePutTenant } from "../../../../apis/useTenantApi";
import { useGetUserDetails } from "../../../../apis/useUserApi";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const { Title } = Typography;

export default function AccountForm() {
  const [form] = Form.useForm();
  const putTenant = usePutTenant({ successMsg: "Updated account settings! 😎" });
  const { data: userDetails } = useGetUserDetails();

  useEffect(() => {
    if (userDetails?.currentTenant) {
      const tenant = userDetails.currentTenant;

      form.setFieldsValue({
        workspaceName: tenant.name,
        maxSpendingLimit: tenant.maxSpendingLimit,
        maxTokenInput: tenant.maxTokenInput,
        maxTokenOutput: tenant.maxTokenOutput,
        maxRecursionLimit: tenant.maxRecursionLimit,
        businessName: tenant.businessName,
        addressLine1: tenant.addressLine1,
        addressLine2: tenant.addressLine2,
        city: tenant.city,
        state: tenant.state,
        zip: tenant.zip,
        timezone: tenant.timezone || dayjs.tz.guess(), // Auto-detect timezone if not set
      });
    }
  }, [form, userDetails]);

  const onSubmit = (body) => {
    putTenant({
      id: userDetails.currentTenant.id,
      body: {
        name: body.workspaceName,
        ...body,
      },
    });
  };

  return (
    <Flex
      vertical
      gap={24}
      style={{ margin: "auto", padding: "20px" }}
    >
      <Card
        bordered
        style={{ borderRadius: "2px" }}
      >
        <Title
          level={4}
          style={{ marginBottom: 12 }}
        >
          Account Settings
        </Title>
        <Form
          name="account-form"
          layout="vertical"
          form={form}
          onFinish={onSubmit}
        >
          <Form.Item
            label="Workspace Name"
            name="workspaceName"
            rules={[{ required: true, message: "Workspace name is required" }]}
          >
            <Input placeholder="Enter workspace name" />
          </Form.Item>

          <Divider />

          <Flex
            gap={12}
            wrap="wrap"
          >
            <Form.Item
              label="Monthly Spending Limit"
              name="maxSpendingLimit"
              tooltip="Set the maximum dollar amount of token consumption for the current billing period."
              style={{ flex: 1, minWidth: "45%" }}
            >
              <InputNumber addonBefore="$" style={{ width: "100%" }} type="number" />
            </Form.Item>
          </Flex>
          <Divider />

          <Form.Item
            label="Business Name"
            name="businessName"
          >
            <Input placeholder="Enter business name" />
          </Form.Item>

          <Flex
            gap={12}
            wrap="wrap"
          >
            <Form.Item
              label="Address Line 1"
              name="addressLine1"
              style={{ flex: 1 }}
            >
              <Input placeholder="Enter address line 1" />
            </Form.Item>
            <Form.Item
              label="Address Line 2"
              name="addressLine2"
              style={{ flex: 1 }}
            >
              <Input placeholder="Enter address line 2" />
            </Form.Item>
          </Flex>

          <Flex
            gap={12}
            wrap="wrap"
          >
            <Form.Item
              label="City"
              name="city"
              style={{ flex: 1 }}
            >
              <Input placeholder="Enter city" />
            </Form.Item>
            <Form.Item
              label="State"
              name="state"
              style={{ flex: 1 }}
            >
              <Input placeholder="Enter state" />
            </Form.Item>
          </Flex>

          <Flex
            gap={12}
            wrap="wrap"
          >
            <Form.Item
              label="Zip Code"
              name="zip"
              style={{ flex: 1 }}
            >
              <Input placeholder="Enter zip code" />
            </Form.Item>
            <Form.Item
              label="Business Time Zone"
              name="timezone"
              style={{ flex: 1 }}
            >
              <Select
                showSearch
                placeholder="Select Timezone"
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
              >
                {Intl.supportedValuesOf("timeZone").map((tz) => (
                  <Select.Option
                    key={tz}
                    value={tz}
                  >
                    {tz}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Flex>

          <Flex justify="end">
            <Button
              type="primary"
              onClick={form.submit}
            >
              Save Changes
            </Button>
          </Flex>
        </Form>
      </Card>
    </Flex>
  );
}
