import apiEndpoints from "./utils/apiEndpoints";
import queryKeys from "./utils/queryKeys";
import useGetApi from "./utils/useGetApi";
import useMutateApi from "./utils/useMutateApi";
import axios from "axios";
import { getCookie } from "./utils/useCookies";
import { ACCESS_TOKEN_KEY } from "../constants/auth";

const useGetTenantWorkflows = () => {
  return useGetApi({
    url: apiEndpoints.workflows,
    queryKey: queryKeys.privateWorkflows.all,
  });
};

const usePostTenantWorkflow = () => {
  return useMutateApi({
    url: apiEndpoints.workflows,
    method: "post",
    invalidateQueries: [queryKeys.privateWorkflows.all],
    successMsg: "Workflow saved! 😎",
  });
};

const usePutTenantWorkflow = () => {
  return useMutateApi({
    url: apiEndpoints.workflows,
    method: "put",
    invalidateQueries: [queryKeys.privateWorkflows.all],
    successMsg: "Workflow updated! 😎",
  });
};

const putTenantWorkflow = (id, request) => {
  try {
    const resp = axios.put(`${apiEndpoints.workflows}/${id}`, request, {
      headers: {
        Authorization: `Bearer ${getCookie(ACCESS_TOKEN_KEY)}`,
      },
    });
    return resp;
  } catch (error) {
    console.error("Error updating workflow:", error);
    throw error;
  }
};

const useDeleteTenantWorkflow = (request) => {
  return useMutateApi({
    url: apiEndpoints.workflows,
    method: "delete",
    invalidateQueries: [queryKeys.privateWorkflows.all],
    successMsg: "Workflow deleted! 👍",
  });
};

const usePutWorkflowCache = () => {
  return useMutateApi({
    url: apiEndpoints.workflowCache,
    method: "put",
    invalidateQueries: [],
  });
};

export {
  useGetTenantWorkflows,
  usePostTenantWorkflow,
  usePutTenantWorkflow,
  useDeleteTenantWorkflow,
  putTenantWorkflow,
  usePutWorkflowCache,
};
