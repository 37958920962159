import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Alert, Modal, Button } from "antd";
import Loading from "../../components/Loading/Loading";
import { AgGridReact } from "ag-grid-react";
import { useGetWorkflowLogsById } from "../../apis/useWorkflowLogsApi";
import ContentLayout from "../../layouts/ContentLayout/ContentLayout";
import dayjs from "dayjs";
import { altFormatTimestamp } from "../../utils/formatTimestamp";
import { EyeOutlined } from "@ant-design/icons";
import LogDetails from "./LogDetails/LogDetails";

import "./WorkflowLogs.scss";

export default function WorkflowLogs() {
  const { workflowId } = useParams();
  const [modalData, setModalData] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { data, refetch, isLoading, isError } = useGetWorkflowLogsById(workflowId);

  React.useEffect(() => {
    refetch();
  }, []);

  const columnDefs = [
    {
      headerName: "Time",
      field: "timestamp",
      valueFormatter: (params) => altFormatTimestamp(dayjs(params.value)),
      sortable: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Level",
      field: "logLevel",
      sortable: true,
      width: 80,
      cellRenderer: (params) => (
        <span
          style={{
            fontWeight: "bold",
            color: params.value === "error" ? "red" : "green",
          }}
        >
          {params.value}
        </span>
      ),
    },
    {
      headerName: "Message",
      field: "message",
      wrapText: true,
      autoHeight: true,
      flex: 3,
    },
    {
      headerName: "Actions",
      field: "id",
      cellRenderer: ({ data }) => (
        <Button
          type="link"
          icon={<EyeOutlined />}
          onClick={() => handleViewLog(data)}
        >
          View Log
        </Button>
      ),
      width: 150,
      cellStyle: { textAlign: "left" },
    },
  ];

  const handleViewLog = (log) => {
    setModalData(log);
    setIsModalVisible(true);
  };

  const handleCloseLog = () => {
    setModalData(null);
    setIsModalVisible(false);
  };

  return (
    <ContentLayout
      title="Logs"
      className="workflow-logs-container"
    >
      {isLoading && <Loading size="large" />}
      {isError && (
        <Alert
          message="Error loading logs"
          type="error"
        />
      )}

      {!isLoading && !isError && (
        <div
          className="ag-theme-alpine"
          style={{
            height: "calc(100vh - 200px)",
            width: "100%",
            maxWidth: "100%",
            overflowX: "auto",
          }}
        >
          <AgGridReact
            rowData={data?.workflowLogs || []}
            columnDefs={columnDefs}
            pagination={false}
            // paginationPageSize={10} // for now no pagination
            domLayout="autoHeight"
            defaultColDef={{ flex: 1, minWidth: 200, resizable: true }}
          />
        </div>
      )}

      <Modal
        title="Details"
        open={isModalVisible}
        onCancel={handleCloseLog}
        footer={null}
        width={800}
      >
        <LogDetails log={modalData} />
      </Modal>
    </ContentLayout>
  );
}
