const apiEndpoints = {
  dashboard: {
    tokenCounter: "/api/dashboard/token-counter",
  },
  documents: {
    documents: "/api/document",
    uploadCredentials: "/api/file/credentials",
  },
  sendMessage: `${process.env.REACT_APP_AI_BACKEND_URL}/api/send-message`,
  stripe: {
    portal: "/api/stripe/portal",
    products: "/api/stripe/products",
    currentUserStripePlan: "/api/stripe/plans/user-plan",
  },
  subscription: {
    checkout: "/api/subscription/checkout",
    portal: "/api/subscription/portal",
  },
  tenant: {
    details: "/api/tenant",
  },
  user: {
    details: "/api/auth/me",
    membership: "/api/membership/me",
  },
  workflows: "/api/private-workflow",
  workflowCache: `${process.env.REACT_APP_AI_BACKEND_URL}/api/update-workflow-cache`,
  workflowsLogs: "/api/private-workflow-logs",
  workflowsSchedules: "/api/private-workflow-schedule",
  job: {
    get: `${process.env.REACT_APP_AI_BACKEND_URL}/api/get-job`,
  },
  google: {
    googleDriveFilesList: `${process.env.REACT_APP_AI_BACKEND_URL}/api/tools/google-drive-files-list`,
  },
  enhancePrompt: `${process.env.REACT_APP_AI_BACKEND_URL}/api/enhance-prompt`,
};

export default apiEndpoints;
