import React from "react";
import { useShallow } from "zustand/react/shallow";
import { useGetUserStripePlan } from "../apis/useStripeApi";
import { useGetTenantWorkflows } from "../apis/useWorkflowApi";
import useWorkflowStore from "../context/stores/useWorkflowStore";

export default function usePermissions() {
  const { data: currentUserStripePlan, isLoading: isCurrentUserStripePlanLoading } = useGetUserStripePlan();
  const { data: workflows } = useGetTenantWorkflows();

  const { nodes } = useWorkflowStore(
    useShallow((state) => ({
      nodes: state.nodes,
    }))
  );
  const hasPermissionToCreateWorkflow = React.useMemo(() => {
    if (isCurrentUserStripePlanLoading) return false;

    if (!currentUserStripePlan) return false;

    if (currentUserStripePlan?.name === "Free") {
      return workflows?.privateWorkflows?.length < 1;
    }

    return true;
  }, [currentUserStripePlan, workflows?.privateWorkflows, isCurrentUserStripePlanLoading]);

  const hasPermissionToAddAgent = React.useMemo(() => {
    if ((!currentUserStripePlan || currentUserStripePlan.name === "Free") && nodes.length >= 2) return false;
    return true;
  }, [currentUserStripePlan, nodes]);

  return {
    hasPermissionToCreateWorkflow,
    hasPermissionToAddAgent,
  };
}
