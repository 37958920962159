import React from "react";
import FeatherIcon from "feather-icons-react";
import useWorkflowStore from "../../../../../context/stores/useWorkflowStore";
import { Typography, Flex } from "antd";
import "./Panel.scss";

const { Title } = Typography;

export default function Panel({ className, title, onPressEnter = () => {}, children, extra }) {
  const { setOpenPanel } = useWorkflowStore();

  const classNames = ["panel-wrapper"];
  if (className) classNames.push(className);

  return (
    <div
      className={classNames.join(" ")}
      onKeyDown={onPressEnter}
    >
      <Flex
        className="panel--header"
        align="center"
        justify="space-between"
      >
        <Flex
          align="center"
          gap={12}
        >
          <FeatherIcon
            icon="chevron-left"
            size={20}
            onClick={() => setOpenPanel(null)}
          />
          {title && (
            <Title
              level={4}
              style={{ margin: 0 }}
            >
              {title}
            </Title>
          )}
        </Flex>

        <Flex>{extra}</Flex>
      </Flex>
      <div className="panel--body">{children}</div>
    </div>
  );
}
