import React from "react";
import { useShallow } from "zustand/react/shallow";
import { useParams } from "react-router-dom";
import { motion } from "framer-motion";
import FeatherIcon from "feather-icons-react";
import hasCircularStructure from "../../../utils/hasCircularStructure";
import { App } from "antd";

// components
import Workflow from "./components/ReactFlow/ReactFlow";
import Toolbar from "./components/Toolbar/Toolbar";
import Logs from "./components/Logs/Logs";
import AgentFormModal from "../../../components/forms/forms/AgentFormModal/AgentFormModal";
import { theme, Divider, FloatButton } from "antd";
import Schedule from "./components/Schedule/Schedule";

// hooks
import useWorkflowStore from "../../../context/stores/useWorkflowStore";
import useWorkflowActions from "../../../context/actions/useWorkflowActions";
import useChatStore from "../../../context/stores/useChatStore";
import useTokenUsage from "../../../utils/useTokenUsage";
import usePermissions from "../../../utils/usePermissions";
import useSpendingLimit from "../../../utils/useSpendingLimit";

// apis
import { useGetTenantWorkflows } from "../../../apis/useWorkflowApi";

// styles
import "./Workflow.scss";

const { useToken } = theme;

export default function Main() {
  const { workflowId } = useParams();
  const { message, modal } = App.useApp();
  const { token } = useToken();
  const { isOverFreeLimit } = useTokenUsage();
  const { isOverLimit } = useSpendingLimit();
  const { data: workflows, isLoading: isWorkflowsLoading } = useGetTenantWorkflows();
  const { onUpdateWorkflow, onSendMessage, getStartingAgents } = useWorkflowActions();
  const { isMessageLoading } = useChatStore();
  const { hasPermissionToAddAgent } = usePermissions();
  const {
    nodes,
    edges,
    openAgentModal,
    setOpenAgentModal,
    selectedWorkflow,
    setSelectedWorkflow,
    workflowTitle,
    openPanel,
    setOpenPanel,
  } = useWorkflowStore(
    useShallow((state) => ({
      nodes: state.nodes,
      edges: state.edges,
      openPanel: state.openPanel,
      setOpenLogs: state.setOpenLogs,
      setOpenPanel: state.setOpenPanel,
      selectedWorkflow: state.selectedWorkflow,
      setSelectedWorkflow: state.setSelectedWorkflow,
      workflowTitle: state.workflowTitle,
      openAgentModal: state.openAgentModal,
      setOpenAgentModal: state.setOpenAgentModal,
    }))
  );

  const [width, setWidth] = React.useState("0%");

  React.useEffect(() => {
    if (isWorkflowsLoading === false) {
      let foundWorkflow = null;
      if (Array.isArray(workflows?.privateWorkflows)) {
        foundWorkflow = workflows.privateWorkflows.find((wf) => wf.id === workflowId);
        setSelectedWorkflow(foundWorkflow);
      }
    }
  }, [workflowId, isWorkflowsLoading, workflows, setSelectedWorkflow]);

  React.useEffect(() => {
    if (openPanel) {
      setWidth(500); // Slide in to occupy 30% of the width
    } else {
      setWidth("0%"); // Slide out to occupy 0% of the width
    }
  }, [openPanel]);

  const iconSize = 16;

  const isCircularStructure = React.useMemo(() => {
    const isFlagged = hasCircularStructure(nodes, edges);
    if (isFlagged) {
      message.error("Circular structure detected 🚨 Please remove loops in your workflow!", 5);
    }
    return isFlagged;
  }, [JSON.stringify(nodes.map((node) => node.id)), JSON.stringify(edges.map((edge) => edge.id)), message]);

  const isSaveDisabled = () => {
    return !workflowTitle || !selectedWorkflow?.id || isCircularStructure;
  };

  const isMissingInputMessage = () => {
    const nodes = getStartingAgents();
    return nodes.some((node) => !node.data.input);
  };

  // Handle workflow execution with spending limit check
  const handleRunWorkflow = () => {
    if (isOverLimit) {
      modal.error({
        title: "Monthly Spending Limit Reached",
        content: (
          <div>
            <p>You have reached your monthly spending limit. Engines cannot be executed until you:</p>
            <ul>
              <li>Increase your monthly spending limit in Settings</li>
              <li>Wait for the next billing cycle</li>
            </ul>
          </div>
        ),
        okText: "Go to Settings",
        onOk: () => {
          window.location.href = "/settings?tab=account";
        },
        cancelText: "Cancel",
      });
    } else {
      onSendMessage();
    }
  };

  const styles = {
    content: {
      maxWidth: token.screenXLMax,
      padding: `0px ${token.padding}px`,
      display: "flex",
      height: "100%",
      position: "relative",
    },
    chatBox: {
      width, // Dynamic width based on state
    },
  };

  return (
    <>
      <Toolbar />
      <div
        id="workflow-content-id"
        className="workflow-content"
        style={styles.content}
      >
        <div className="react-flow-container">
          <Workflow />
          <FloatButton.Group style={{ right: 10, top: 15, position: "absolute" }}>
            <FloatButton
              tooltip={
                isMissingInputMessage()
                  ? "Please enter input messages for all starting agents"
                  : isOverLimit
                  ? "Monthly spending limit reached"
                  : "Start engine"
              }
              disabled={
                isMissingInputMessage() ||
                isMessageLoading ||
                !selectedWorkflow?.id ||
                isOverFreeLimit ||
                isCircularStructure ||
                isOverLimit
              }
              icon={
                <FeatherIcon
                  icon="play"
                  size={iconSize}
                />
              }
              type="primary"
              onClick={handleRunWorkflow}
              style={{ right: 60 }}
            />
            <FloatButton
              onClick={() => setOpenPanel("logs")}
              tooltip={"Open logs"}
              icon={
                <FeatherIcon
                  icon="columns"
                  size={iconSize}
                />
              }
            />
            <FloatButton
              onClick={() => setOpenPanel("schedule")}
              tooltip={hasPermissionToAddAgent ? "Schedule engine" : "Upgrade to schedule engine"}
              disabled={!hasPermissionToAddAgent}
              icon={
                <FeatherIcon
                  icon="clock"
                  size={iconSize}
                />
              }
            />
            <FloatButton
              onClick={() =>
                onUpdateWorkflow({
                  ...selectedWorkflow,
                  name: workflowTitle,
                  config: JSON.stringify({
                    nodes,
                    edges,
                  }),
                })
              }
              tooltip={"Save engine"}
              disabled={isSaveDisabled()}
              icon={
                <FeatherIcon
                  icon="save"
                  size={iconSize}
                />
              }
            />
            <FloatButton
              onClick={() => setOpenAgentModal(true)}
              tooltip={hasPermissionToAddAgent ? "Add Agent" : "Upgrade to add agents"}
              disabled={!hasPermissionToAddAgent}
              icon={
                <FeatherIcon
                  icon="plus"
                  size={iconSize}
                />
              }
            />
          </FloatButton.Group>
        </div>
        {openPanel && (
          <Divider
            type={"vertical"}
            style={{ height: "100%" }}
          />
        )}
        <motion.div
          className="chat-container"
          animate={{ width }}
          initial={{ width: "0%" }}
          style={styles.chatBox}
        >
          {openPanel === "logs" && <Logs />}
          {openPanel === "schedule" && <Schedule />}
        </motion.div>
      </div>
      <AgentFormModal
        open={openAgentModal}
        setOpen={setOpenAgentModal}
      />
    </>
  );
}
