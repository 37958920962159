export default function useCookies() {
  const getCookie = (key) => {
    let cookieValue = null;
    if (document.cookie && document.cookie !== "") {
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.substring(0, key.length + 1) === key + "=") {
          cookieValue = decodeURIComponent(cookie.substring(key.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  };

  const setCookie = (key, value) => {
    document.cookie = `${key}=${value}; path=/; max-age=${60 * 60 * 24}; same-site=strict;${
      process.env.NODE_ENV === "production" ? "secure" : ""
    }`;
  };

  const deleteCookie = (key) => {
    document.cookie = key + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  };

  return {
    getCookie,
    setCookie,
    deleteCookie,
  };
}

export const getCookie = (key) => {
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, key.length + 1) === key + "=") {
        cookieValue = decodeURIComponent(cookie.substring(key.length + 1));
        break;
      }
    }
  }
  return cookieValue;
};
