import React from "react";
import { ReactFlow as ReactFlowPkg, Controls, Background, useNodesState, useEdgesState, addEdge } from "@xyflow/react";
import { useShallow } from "zustand/react/shallow";
import { shallow } from "zustand/shallow";
import useWorkflowStore from "../../../../../context/stores/useWorkflowStore";
import { usePutTenantWorkflow } from "../../../../../apis/useWorkflowApi";
import GeneralNode from "./components/GeneralNode/GeneralNode";
import StartingNode from "./components/StartingNode/StartingNode";
const nodeTypes = {
  GeneralNode,
  StartingNode,
};

const selector = (store) => ({
  nodes: store.nodes,
  edges: store.edges,
  onNodesChange: store.onNodesChange,
  onEdgesChange: store.onEdgesChange,
  addEdge: store.addEdge,
  onEdgeClick: store.onEdgeClick,
  onNodeClick: store.onNodeClick,
});

export default function ReactFlow() {
  const workflowStore = useWorkflowStore(selector, shallow);
  const updateTenantWorkflow = usePutTenantWorkflow();
  const { nodes, edges, selectedWorkflow } = useWorkflowStore(
    useShallow((state) => ({
      nodes: state.nodes,
      edges: state.edges,
      setOpenLogs: state.setOpenLogs,
      selectedWorkflow: state.selectedWorkflow,
    }))
  );

  return (
    <ReactFlowPkg
      nodes={workflowStore.nodes}
      edges={workflowStore.edges}
      nodeTypes={nodeTypes}
      onNodesChange={workflowStore.onNodesChange}
      onEdgesChange={workflowStore.onEdgesChange}
      onConnect={workflowStore.addEdge}
      onEdgeClick={workflowStore.onEdgeClick}
      onNodeClick={workflowStore.onNodeClick}
      onNodesDelete={(data) => {
        const node = data[0];
        const updatedNodes = nodes.filter((item) => item.id !== node.id);
        const updatedEdges = edges.filter((item) => item.source !== node.id && item.target !== node.id);

        updateTenantWorkflow({
          id: selectedWorkflow.id,
          body: {
            name: selectedWorkflow.name,
            description: "Test",
            type: "Sequential",
            config: JSON.stringify({
              nodes: updatedNodes,
              edges: updatedEdges,
            }),
            isPrivate: true,
          },
        });
      }}
      fitView
    >
      <Controls />
      <Background />
    </ReactFlowPkg>
  );
}
