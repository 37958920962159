import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Avatar, Grid, Menu } from "antd";
import FeatherIcon from "feather-icons-react";
import { useGetUserDetails } from "../../apis/useUserApi";
import useAuthApi from "../../apis/useAuthApi";
import { useGetUserStripePlan } from "../../apis/useStripeApi";
import "./NavigationComponents.scss";

const { useBreakpoint } = Grid;

export default function useNavigationComponents() {
  const { data: user, isRefetching: isUserRefetching } = useGetUserDetails();
  const { signout } = useAuthApi();
  const navigate = useNavigate();
  const location = useLocation();
  const screens = useBreakpoint();
  const [open, setOpen] = React.useState(false);
  const [selectedKeys, setSelectedKeys] = React.useState(["workflow"]);
  const { data: currentUserStripePlan } = useGetUserStripePlan();

  const initials = React.useMemo(() => {
    if (user) {
      const firstLetter = user?.firstName?.[0] ? user.firstName[0].toUpperCase() : "";
      const lastLetter = user?.lastName?.[0] ? user.lastName[0].toUpperCase() : "";
      return `${firstLetter}${lastLetter}`;
    }
  }, [user, isUserRefetching]);

  React.useEffect(() => {
    if (location.pathname === "/") {
      setSelectedKeys(["dashboard"]);
    } else {
      setSelectedKeys([location.pathname.split("/")[1]]);
    }
  }, [location.pathname]);

  const shouldDisplayOnMobile = screens.lg || screens.xs;

  const styles = {
    navMenu: {
      backgroundColor: "transparent",
      border: 0,
      flexGrow: 1,
    },
    footerMenu: {
      backgroundColor: "transparent",
      border: 0,
      marginBottom: 11,
    },
  };

  const iconSize = 16;

  const items = React.useMemo(() => {
    const nav = [
      {
        key: "dashboard",
        tooltip: "Dashboard",
        label: <Avatar className="menu-avatar">{initials}</Avatar>,
        onClick: () => navigate("/"),
      },
      {
        key: "engines",
        icon: (
          <FeatherIcon
            icon={"git-pull-request"}
            size={iconSize}
          />
        ),
        label: "Engines",
        onClick: () => navigate("/engines"),
      },
      {
        key: "settings",
        icon: (
          <FeatherIcon
            icon={"settings"}
            size={iconSize}
          />
        ),
        label: "Settings",
        onClick: () => navigate("/settings"),
      },
    ];

    if (currentUserStripePlan?.name === "Free") {
      nav.push({
        key: "subscription",
        icon: (
          <FeatherIcon
            className="upgrade-icon"
            icon={"arrow-up-circle"}
            size={iconSize}
          />
        ),
        label: "Upgrade",
        onClick: () => navigate("/subscription"),
      });
    }

    return nav;
  }, [initials, location.pathname, selectedKeys, currentUserStripePlan, navigate]);

  const footerItems = [
    {
      label: "Logout",
      icon: (
        <FeatherIcon
          icon={"log-out"}
          size={iconSize}
        />
      ),
      key: "3",
      onClick: signout,
    },
  ];

  const menus = (
    <>
      <Menu
        theme="light"
        mode="inline"
        style={styles.navMenu}
        items={items}
        onSelect={({ selectedKeys }) => setSelectedKeys(selectedKeys)}
        selectedKeys={selectedKeys}
        className="main-sidenav"
      />
      <Menu
        theme="light"
        mode="inline"
        style={styles.footerMenu}
        items={footerItems}
      />
    </>
  );

  return {
    menus,
    open,
    setOpen,
    shouldDisplayOnMobile,
  };
}
