import React, { useState } from 'react';
import { Button, Tooltip, message } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import axios from 'axios';
import apiEndpoints from "../../../../apis/utils/apiEndpoints";

const EnhancePromptButton = ({ 
  form,
  model, 
  agentName, 
  temperature, 
  tools, 
  onEnhancedPrompt 
}) => {
  const [loading, setLoading] = useState(false);

  const handleEnhance = async () => {
    // Get the current prompt value from the form
    const currentPrompt = form.getFieldValue('prompt');
    
    if (!currentPrompt) {
      message.warning('Please enter a prompt first');
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(apiEndpoints.enhancePrompt, {
        prompt: currentPrompt,
        model,
        agentName,
        temperature,
        tools
      });

      if (response.data.success) {
        onEnhancedPrompt(response.data.data.enhancedPrompt);
        message.success('Prompt enhanced successfully');
      } else {
        message.error('Failed to enhance prompt');
      }
    } catch (error) {
      console.error('Error enhancing prompt:', error);
      message.error('Failed to enhance prompt');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Tooltip title="Enhance your prompt with AI">
      <Button
        type="text"
        icon={<ReloadOutlined />}
        onClick={handleEnhance}
        loading={loading}
        style={{ marginLeft: 8 }}
      >
        Enhance Prompt
      </Button>
    </Tooltip>
  );
};

export default EnhancePromptButton; 