import React from "react";
import { themeMaterial } from "ag-grid-community";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";
import { QuestionCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { Button, Flex, Tooltip, Popconfirm, Typography, Spin, Empty } from "antd";
import { AgGridReact } from "ag-grid-react";
import ContentLayout from "../../../layouts/ContentLayout/ContentLayout";
import useWorkflowActions from "../../../context/actions/useWorkflowActions";
import WorkflowForm from "../../../components/forms/forms/WorkflowForm/WorkflowForm";
import usePermissions from "../../../utils/usePermissions";
import { useGetUserStripePlan } from "../../../apis/useStripeApi";

// apis
import { useGetTenantWorkflows } from "../../../apis/useWorkflowApi";

const Text = Typography.Text;

export default function Workflows() {
  const { data: workflows, isLoading: isWorkflowsLoading } = useGetTenantWorkflows();
  const { hasPermissionToCreateWorkflow } = usePermissions();
  const { isLoading: isCurrentUserStripePlanLoading } = useGetUserStripePlan();
  const { onCreateWorkflow, onDeleteWorkflow } = useWorkflowActions();
  const [openWorkflowForm, setOpenWorkflowForm] = React.useState(false);
  const [selectedWorkflow, setSelectedWorkflow] = React.useState(null);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [deletingId, setDeletingId] = React.useState(null);

  const handleDeleteWorkflow = (id) => {
    setIsDeleting(true);
    setDeletingId(id);
    onDeleteWorkflow(id, {
      onSuccess: () => {
        setIsDeleting(false);
        setDeletingId(null);
      },
      onError: () => {
        setIsDeleting(false);
        setDeletingId(null);
      },
    });
  };

  const gridOptions = {
    columnDefs: [
      {
        headerName: "Name",
        field: "name",
        editable: true,
        cellRenderer: ({ data }) => <Link to={`/engines/${data.id}`}>{data.name}</Link>,
        flex: 1,
      },
      {
        headerName: "Description",
        field: "description",
        editable: true,
        flex: 1,
      },
      {
        headerName: "# of Agents",
        field: "",
        valueGetter: (params) => {
          let nodes = [];
          try {
            nodes = JSON.parse(params?.data?.config)?.nodes;
          } catch (e) {
            console.log(e);
          }
          return nodes.length || 0;
        },
        width: 120,
      },
      {
        headerName: "Is active?",
        field: "is_active",
        cellRenderer: ({ data }) => (data.is_active ? <Text type="success">Yes</Text> : <Text type="danger">No</Text>),
        width: 130,
      },
      {
        headerName: "Created at",
        field: "createdAt",
        valueFormatter: (params) => {
          return dayjs(params.value).format("DD MMMM, YYYY");
        },
      },
      {
        headerName: "Actions",
        field: "id",
        width: 140,
        cellRenderer: ({ data }) => (
          <Flex
            style={{ marginLeft: -10 }}
            align="center"
          >
            <Link to={`/engine-logs/${data.id}`}>
              <Tooltip title="View logs">
                <Button
                  icon={
                    <FeatherIcon
                      icon="book-open"
                      size={10}
                    />
                  }
                  shape="circle"
                  type="text"
                />
              </Tooltip>
            </Link>
            <Tooltip title="Update engine">
              <Button
                icon={
                  <FeatherIcon
                    icon="edit-2"
                    size={10}
                  />
                }
                shape="circle"
                type="text"
                onClick={() => {
                  setSelectedWorkflow(data);
                  setOpenWorkflowForm(true);
                }}
              />
            </Tooltip>
            <Tooltip title={hasPermissionToCreateWorkflow ? "Duplicate engine" : "Upgrade to duplicate engine"}>
              <Button
                icon={
                  <FeatherIcon
                    icon="copy"
                    size={10}
                  />
                }
                disabled={!hasPermissionToCreateWorkflow}
                shape="circle"
                type="text"
                onClick={() => {
                  onCreateWorkflow(data);
                }}
              />
            </Tooltip>
            <Popconfirm
              title="Delete workflow?"
              description="Are you sure to delete this workflow?"
              okText="Delete"
              okType="danger"
              placement="left"
              onConfirm={() => handleDeleteWorkflow(data.id)}
              icon={
                <QuestionCircleOutlined
                  style={{
                    color: "red",
                  }}
                />
              }
            >
              <Tooltip title="Delete engine">
                <Button
                  icon={
                    isDeleting && deletingId === data.id ? (
                      <Spin size="small" />
                    ) : (
                      <FeatherIcon
                        icon="trash-2"
                        size={10}
                      />
                    )
                  }
                  shape="circle"
                  type="text"
                  disabled={isDeleting && deletingId === data.id}
                />
              </Tooltip>
            </Popconfirm>
          </Flex>
        ),
      },
    ],
    defaultColDef: { sortable: true, resizable: true },
  };

  if (isCurrentUserStripePlanLoading) return;

  const actions = (
    <Tooltip
      title={hasPermissionToCreateWorkflow ? "" : "Upgrade to add engines"}
      open={hasPermissionToCreateWorkflow ? false : undefined}
    >
      <Button
        type="primary"
        disabled={!hasPermissionToCreateWorkflow}
        icon={
          <FeatherIcon
            icon="plus"
            size={12}
            style={{ marginTop: 2 }}
          />
        }
        onClick={() => setOpenWorkflowForm(true)}
      >
        Create Engine
      </Button>
    </Tooltip>
  );

  const renderContent = () => {
    if (isWorkflowsLoading) {
      return (
        <div style={{ display: "flex", justifyContent: "center", padding: "50px" }}>
          <Spin
            size="large"
            tip="Loading engines..."
          />
        </div>
      );
    }

    if (!workflows?.privateWorkflows || workflows.privateWorkflows.length === 0) {
      return (
        <Empty
          description="No engines found"
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        >
          {hasPermissionToCreateWorkflow && (
            <Button
              type="primary"
              onClick={() => setOpenWorkflowForm(true)}
              icon={
                <FeatherIcon
                  icon="plus"
                  size={12}
                  style={{ marginTop: 2 }}
                />
              }
            >
              Create Engine
            </Button>
          )}
        </Empty>
      );
    }

    return (
      <AgGridReact
        theme={themeMaterial}
        rowData={workflows?.privateWorkflows || []}
        gridOptions={gridOptions}
        domLayout="autoHeight"
      />
    );
  };

  return (
    <ContentLayout
      title="Engines"
      actions={actions}
    >
      {renderContent()}
      <WorkflowForm
        workflow={selectedWorkflow}
        open={openWorkflowForm}
        setOpen={setOpenWorkflowForm}
      />
    </ContentLayout>
  );
}
