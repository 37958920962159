import React from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import useCookies from "../apis/utils/useCookies";
import { ACCESS_TOKEN_KEY, APP_STATE } from "../constants/auth";
import { isExpired } from "react-jwt";

import { theme, Spin, Flex } from "antd";

import ProtectedRoutes from "../components/ProtectedRoutes/ProtectedRoutes";
import MainLayout from "../layouts/MainLayout/MainLayout";
import AuthLayout from "../layouts/AuthLayout/AuthLayout";
import Workflows from "../containers/workflows/Workflows/Workflows";
import Workflow from "../containers/workflows/Workflow/Workflow";
import WorkflowLogsDetail from "../containers/WorkflowLogs/WorkflowLogs";

import SubscriptionPlans from "../containers/SubscriptionPlans/SubscriptionPlans";
import Settings from "../containers/Settings/Settings";
import Dashboard from "../containers/Dashboard/Dashboard";
import CreateWorkspace from "../containers/CreateWorkspace/CreateWorkspace";

import Signin from "../containers/auth/Signin/Signin";
import Signup from "../containers/auth/Signup/Signup";
import VerifyEmail from "../containers/auth/VerifyEmail/VerifyEmail";
import ConfirmEmail from "../containers/auth/ConfirmEmail/ConfirmEmail";

import useAppStore from "../context/stores/useAppStore";
import { useGetUserDetails } from "../apis/useUserApi";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useQueryClient } from "@tanstack/react-query";

const { useToken } = theme;

export default function App() {
  const navigate = useNavigate();
  const { data: user, isLoading: isUserLoading, refetch: refetchUser } = useGetUserDetails();
  const { token } = useToken();
  const { appState, setAppState } = useAppStore();
  const { getCookie, setCookie } = useCookies();
  const queryClient = useQueryClient();

  token.colorFillAlter = "transparent";

  React.useEffect(() => {
    let token = getCookie(ACCESS_TOKEN_KEY);
    if (isUserLoading === false) {
      if (token && user?.emailVerified === false) {
        setAppState(APP_STATE.unverified);
        navigate("/verify-email");
      } else if (user?.memberships?.length === 0) {
        setAppState(APP_STATE.noTenant);
      } else if (token && !isExpired(token)) {
        setAppState(APP_STATE.authenticated);
      } else {
        setCookie(ACCESS_TOKEN_KEY, null);
        setAppState(APP_STATE.unauthenticated);
        queryClient.removeQueries();
      }
    }
  }, [isUserLoading]);

  React.useEffect(() => {
    if (appState === APP_STATE.authenticated) refetchUser();
  }, [appState]);

  if (isUserLoading || appState === APP_STATE.loading) {
    return (
      <Flex
        justify="center"
        align="center"
        style={{ height: "100vh", width: "100vw" }}
      >
        <Spin size="large" />
      </Flex>
    );
  }

  return (
    <Routes>
      <Route element={<ProtectedRoutes />}>
        <Route element={<MainLayout />}>
          <Route
            path="/"
            element={<Dashboard />}
          />
          <Route
            path="/engines/:workflowId"
            element={<Workflow />}
          />
          <Route
            path="/engines"
            element={
              <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID_TOOLS}>
                <Workflows />
              </GoogleOAuthProvider>
            }
          />
          <Route
            path="/engine-logs/:workflowId"
            element={<WorkflowLogsDetail />}
          />
          <Route
            path="/subscription"
            element={<SubscriptionPlans />}
          />
          <Route
            path="/settings"
            element={
              <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID_TOOLS}>
                <Settings />
              </GoogleOAuthProvider>
            }
          />
        </Route>
      </Route>
      <Route
        path="/create-workspace"
        element={<CreateWorkspace />}
      />
      <Route element={<AuthLayout />}>
        <Route
          path="/signin"
          element={<Signin />}
        />
        <Route
          path="/signup"
          element={<Signup />}
        />
        <Route
          path="/verify-email"
          element={<VerifyEmail />}
        />
        <Route
          path="/auth/verify-email/confirm"
          element={<ConfirmEmail />}
        />
      </Route>
    </Routes>
  );
}
