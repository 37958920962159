import useMutateApi from "./utils/useMutateApi";
import apiEndpoints from "./utils/apiEndpoints";
import queryKeys from "./utils/queryKeys";
import useGetApi from "./utils/useGetApi";

export const useGetWorkflowSchedules = (workflowId) => {
  return useGetApi({
    url: apiEndpoints.workflowsSchedules,
    queryKey: queryKeys.workflowSchedules.workflow(workflowId),
    params: workflowId ? { workflowId } : {},
    queryOptions: {
      enabled: !!workflowId,
    },
  });
};

export const useCreateWorkflowSchedule = (workflowId) => {
  return useMutateApi({
    url: apiEndpoints.workflowsSchedules,
    method: "POST",
    invalidateQueries: [queryKeys.workflowSchedules.workflow(workflowId)],
  });
};

export const useUpdateWorkflowSchedule = (workflowId) => {
  return useMutateApi({
    url: apiEndpoints.workflowsSchedules,
    method: "PUT",
    invalidateQueries: [queryKeys.workflowSchedules.workflow(workflowId), queryKeys.privateWorkflows.all],
  });
};

export const useDeleteWorkflowSchedule = (workflowId) => {
  return useMutateApi({
    url: apiEndpoints.workflowsSchedules,
    method: "DELETE",
    invalidateQueries: [queryKeys.workflowSchedules.workflow(workflowId)],
  });
};

export const useUpdateAllWorkflowSchedule = (workflowId) => {
  return useMutateApi({
    url: apiEndpoints.workflowsSchedules,
    method: "PATCH",
    invalidateQueries: [queryKeys.workflowSchedules.workflow(workflowId)],
  });
};
