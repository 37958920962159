// import { shallow } from "zustand/shallow";
import { Flex, Typography, Avatar, Button, Form, Select } from "antd";
import { MODELS } from "../../../../../../../components/forms/forms/enum";
import { Handle, Position } from "@xyflow/react";
import FeatherIcon from "feather-icons-react";
import useWorkflowActions from "../../../../../../../context/actions/useWorkflowActions";

import "./GeneralNode.scss";

const { Text } = Typography;

export default function GeneralNode(props) {
  const { data, isConnectable } = props;
  const [form] = Form.useForm();
  const { onSelectAgent } = useWorkflowActions();
  const classNames = ["general-node-container"];
  if (data?.selected) classNames.push("node-selected");

  return (
    <div className={classNames.join(" ")}>
      <Flex vertical>
        <Flex
          className="general-node-label-container"
          align="center"
          justify="space-between"
          gap={8}
        >
          <Flex
            align="center"
            gap={8}
          >
            <Avatar
              size={26}
              className="agent-avatar"
              icon={
                <FeatherIcon
                  icon={"user"}
                  size={16}
                />
              }
            />
            <Text strong>{data.name}</Text>
          </Flex>
          <Button
            shape="circle"
            icon={
              <FeatherIcon
                icon={"edit-3"}
                size={16}
              />
            }
            onClick={() => onSelectAgent(data)}
          ></Button>
        </Flex>
        <Flex className="general-node-inner-container">
          <Form
            name={data.name}
            layout="vertical"
            form={form}
            initialValues={data}
            style={{ width: "100%" }}
          >
            <Form.Item
              label="Model"
              name="model"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                options={MODELS}
                value={data.model}
                style={{ width: "100%" }}
              >
                {MODELS.map((item, i) => (
                  <Select.Option
                    key={i}
                    value={item.value}
                  >
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </Flex>
      </Flex>

      <Handle
        type="target"
        position={Position.Left}
        style={{ background: "#555" }}
        onConnect={(params) => console.log("handle onConnect", params)}
        // isConnectable={isConnectable}
      />

      <Handle
        type="source"
        position={Position.Right}
        id="b"
        // style={{ bottom: 10, top: 'auto', background: '#555' }}
        isConnectable={isConnectable}
      />
    </div>
  );
}
