// Import Dayjs and required plugins
const dayjs = require("dayjs");
const relativeTime = require("dayjs/plugin/relativeTime");
const updateLocale = require("dayjs/plugin/updateLocale");
const isSameOrBefore = require("dayjs/plugin/isSameOrBefore");
const advancedFormat = require("dayjs/plugin/advancedFormat");
const localizedFormat = require("dayjs/plugin/localizedFormat");

// Extend Dayjs with the plugins
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.extend(isSameOrBefore);
dayjs.extend(advancedFormat);
dayjs.extend(localizedFormat);
// Update locale for relative time
dayjs.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "a few seconds",
    m: "a minute",
    mm: "%d minutes",
    h: "an hour",
    hh: "%d hours",
    d: "a day",
    dd: "%d days",
    M: "a month",
    MM: "%d months",
    y: "a year",
    yy: "%d years",
  },
});

// Function to format timestamp
export default function formatTimestamp(timestamp) {
  if (!timestamp) return '';
  
  const inputDate = dayjs(timestamp);
  const now = dayjs();
  const diffMinutes = now.diff(inputDate, "minute");
  const diffHours = now.diff(inputDate, "hour");
  const diffDays = now.diff(inputDate, "day");
  const time = inputDate.format("h:mm a"); // Example: 6:23PM

  // For very recent timestamps (less than 1 hour)
  if (diffMinutes < 60) {
    return inputDate.fromNow(); // "a few seconds ago", "5 minutes ago", etc.
  } 
  // For today's timestamps
  else if (diffHours < 24 && inputDate.format('YYYY-MM-DD') === now.format('YYYY-MM-DD')) {
    return `Today @ ${time}`;
  } 
  // For yesterday's timestamps
  else if (diffDays === 1) {
    return `Yesterday @ ${time}`;
  } 
  // For timestamps within the last week
  else if (diffDays < 7) {
    return `${inputDate.format("ddd")} @ ${time}`; // Example: "Wed @ 6:23PM"
  } 
  // For timestamps within the current year
  else if (inputDate.format('YYYY') === now.format('YYYY')) {
    return `${inputDate.format("MMM D")} @ ${time}`; // Example: "Jan 20 @ 6:23PM"
  } 
  // For older timestamps
  else {
    return `${inputDate.format("MMM D, YYYY")} @ ${time}`; // Example: "Jan 20, 2022 @ 6:23PM"
  }
}

export const altFormatTimestamp = (inputDate) => {
  if (!inputDate || !dayjs.isDayjs(inputDate)) {
    inputDate = dayjs(inputDate);
  }
  
  const now = dayjs();
  const diffDays = inputDate.startOf("day").diff(now.startOf("day"), "days");
  const time = inputDate.format("h:mm a"); // Example: 6:23PM

  if (diffDays === 0) {
    return `Today @ ${time}`;
  } else if (diffDays === 1) {
    return `Tomorrow @ ${time}`;
  } else if (diffDays === -1) {
    return `Yesterday @ ${time}`;
  } else if (Math.abs(diffDays) < 7) {
    return `${inputDate.format("ddd")} @ ${time}`; // Example: "Wed @ 6:23PM"
  } else {
    return `${inputDate.format("MMM D, YYYY")} @ ${time}`; // Example: "Jan 20, 2022 @ 6:23PM"
  }
};
