const queryKeys = {
  dashboard: {
    tokenCounter: ["tokenCounter"],
  },
  documents: {
    all: ["documents"],
  },
  privateWorkflows: {
    all: ["privateWorkflows"],
    details: (id) => [...queryKeys.privateWorkflows.all, id],
  },
  workflowLogs: {
    all: ["workflowLogs"],
    details: (id) => [...queryKeys.workflowLogs.all, id],
  },
  workflowSchedules: {
    all: ["schedules"],
    workflow: (id) => [...queryKeys.workflowSchedules.all, id],
  },
  stripe: {
    portal: ["portal"],
    product: ["product"],
    products: ["products"],
    currentUserStripePlan: ["currentUserStripePlan"],
  },
  user: {
    details: ["userDetails"],
    tenants: ["userDetails", "tenants"],
    memberships: ["userDetails", "memberships"],
  },
  google: {
    googleDriveFilesList: ["googleDriveFilesList"],
  },
};

export default queryKeys;
