import React, { useState, useEffect } from "react";
import { Card, message, Button, Popconfirm, Switch, Tooltip, Spin } from "antd";
import dayjs from "dayjs";
import cronParser from "cron-parser";
import ScheduleForm from "../ScheduleForm/ScheduleForm";
import FeatherIcon from "feather-icons-react";
import { altFormatTimestamp } from "../../../../../../../utils/formatTimestamp";

export default function ScheduleCard({
  schedule,
  onEditSchedule,
  index,
  onDeleteSchedule,
  onToggleActive,
  disableActivation,
}) {
  const [isActive, setIsActive] = useState(schedule.isActive || false);
  const [isLoading, setIsLoading] = useState(false);

  // Update local state when schedule prop changes
  useEffect(() => {
    setIsActive(schedule.isActive || false);
  }, [schedule.isActive]);

  const getNextRun = (expression) => {
    try {
      const interval = cronParser.parseExpression(expression, { iterator: true });
      const nextRun = interval.next();
      return altFormatTimestamp(dayjs(nextRun?.value));
    } catch (error) {
      message.error("Invalid cron expression");
      return "Invalid Schedule";
    }
  };

  const handleToggle = (checked) => {
    setIsLoading(true);
    if (onToggleActive) {
      onToggleActive(schedule.id, checked, {
        onSuccess: () => {
          setIsActive(checked);
          setIsLoading(false);
        },
        onError: () => {
          // Revert back to original state on error
          setIsActive(!checked);
          setIsLoading(false);
        },
      });
    }
  };

  const toggleSwitch = (
    <Spin
      spinning={isLoading}
      size="small"
    >
      <Switch
        key={`toggle-btn-${schedule.cronExpression}`}
        checked={isActive}
        onChange={handleToggle}
        checkedChildren="Active"
        unCheckedChildren="Inactive"
        disabled={(disableActivation && !isActive) || isLoading}
      />
    </Spin>
  );

  return (
    <Card
      title={`Next run ${getNextRun(schedule.cronExpression)}`}
      extra={[
        <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
          {disableActivation && !isActive ? (
            <Tooltip
              key={`toggle-tooltip-${schedule.cronExpression}`}
              title="Monthly spending limit reached"
            >
              {toggleSwitch}
            </Tooltip>
          ) : (
            toggleSwitch
          )}
          <Button
            key={`edit-btn-${schedule.cronExpression}`}
            type="text"
            onClick={() => onEditSchedule(index)}
            icon={
              <FeatherIcon
                icon="edit-2"
                size={12}
              />
            }
          />
          <Popconfirm
            title="Delete trigger?"
            description="Are you sure to delete?"
            onConfirm={() => onDeleteSchedule(index)}
            okText="Yes"
            cancelText="No"
            placement="left"
          >
            <Button
              key={`delete-btn-${schedule.cronExpression}`}
              type="text"
              danger
              icon={
                <FeatherIcon
                  icon="trash-2"
                  size={12}
                />
              }
            />
          </Popconfirm>
        </div>,
      ]}
    >
      <ScheduleForm
        schedule={schedule}
        isFormDisabled={true}
      />
    </Card>
  );
}
