import { useEffect, useMemo, useState } from 'react';
import { useGetTokenCounter } from '../apis/useDashboardApi';
import { useGetUserDetails } from '../apis/useUserApi';

// Constants for warning thresholds
const WARNING_THRESHOLD = 0.8; // 80% of limit
const CRITICAL_THRESHOLD = 0.95; // 95% of limit

export default function useSpendingLimit() {
  const { data: tokenCounter } = useGetTokenCounter();
  const { data: userDetails } = useGetUserDetails();
  const [currentSpending, setCurrentSpending] = useState(0);
  const [spendingLimit, setSpendingLimit] = useState(null);
  const [percentUsed, setPercentUsed] = useState(0);
  const [isOverLimit, setIsOverLimit] = useState(false);
  const [isNearLimit, setIsNearLimit] = useState(false);
  const [isApproachingLimit, setIsApproachingLimit] = useState(false);

  // Calculate current spending based on token usage
  useEffect(() => {
    if (tokenCounter?.tokenUsage && Array.isArray(tokenCounter.tokenUsage)) {
      let totalSpending = 0;
      
      tokenCounter.tokenUsage.forEach(item => {
        // Calculate cost in dollars: quantity * amountPerUnit / 100 / 1000000
        // amountPerUnit is in cents per million tokens
        const itemCost = (item.quantity * item.amountPerUnit) / 100 / 1000000;
        totalSpending += itemCost;
      });
      
      setCurrentSpending(totalSpending);
    }
  }, [tokenCounter]);

  // Get spending limit from user details
  useEffect(() => {
    if (userDetails?.currentTenant?.maxSpendingLimit) {
      setSpendingLimit(userDetails.currentTenant.maxSpendingLimit);
    }
  }, [userDetails]);

  // Calculate percentage used and set warning flags
  useEffect(() => {
    if (spendingLimit && spendingLimit > 0) {
      const percent = (currentSpending / spendingLimit) * 100;
      setPercentUsed(percent);
      
      setIsOverLimit(currentSpending >= spendingLimit);
      setIsNearLimit(currentSpending >= spendingLimit * CRITICAL_THRESHOLD);
      setIsApproachingLimit(currentSpending >= spendingLimit * WARNING_THRESHOLD);
    } else {
      setPercentUsed(0);
      setIsOverLimit(false);
      setIsNearLimit(false);
      setIsApproachingLimit(false);
    }
  }, [currentSpending, spendingLimit]);

  // Format currency for display
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(amount);
  };

  // Generate warning message based on current status
  const warningMessage = useMemo(() => {
    if (isOverLimit) {
      return `You have reached your monthly spending limit of ${formatCurrency(spendingLimit)}. Engines will not run until you increase your limit or the billing period resets.`;
    } else if (isNearLimit) {
      return `You are very close to your monthly spending limit of ${formatCurrency(spendingLimit)}. Consider increasing your limit to avoid service interruption.`;
    } else if (isApproachingLimit) {
      return `You are approaching your monthly spending limit of ${formatCurrency(spendingLimit)}.`;
    }
    return null;
  }, [isOverLimit, isNearLimit, isApproachingLimit, spendingLimit]);

  return {
    currentSpending,
    spendingLimit,
    percentUsed,
    isOverLimit,
    isNearLimit,
    isApproachingLimit,
    warningMessage,
    formatCurrency
  };
} 