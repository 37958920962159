import React from "react";
import PageTitle from "../../components/PageTitle/PageTitle";
import { Grid, theme } from "antd";

const { useToken } = theme;
const { useBreakpoint } = Grid;

export default function ContentLayout({ className, title, subtitle, children, actions }) {
  const { token } = useToken();
  const screens = useBreakpoint();

  const styles = {
    section: {
      backgroundColor: token.colorBgContainer,
      padding: `${token.padding}px 0px`,
      height: "100%",
    },
    container: {
      margin: "0 auto",
      maxWidth: screens.lg ? token.screenXL : token.screenSM,
      padding: screens.md ? `0px ${token.paddingLG}px` : `0px ${token.padding}px`,
    },
  };

  return (
    <>
      <PageTitle
        title={title}
        subtitle={subtitle}
        actions={actions}
      />
      <div style={styles.section}>
        <div
          className={className}
          style={styles.container}
        >
          {children}
        </div>
      </div>
    </>
  );
}
