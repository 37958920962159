import apiEndpoints from "./utils/apiEndpoints";
import queryKeys from "./utils/queryKeys";
import useGetApi from "./utils/useGetApi";

const useGetTenantWorkflowsLogs = () => {
  return useGetApi({
    url: apiEndpoints.workflowsLogs,
    queryKey: queryKeys.workflowLogs.all,
  });
};

export const useGetWorkflowLogsById = (workflowId) => {
  return useGetApi({
    url: `${apiEndpoints.workflowsLogs}/${workflowId}`,
    queryKey: queryKeys.workflowLogs.details(workflowId),
  });
};

export { useGetTenantWorkflowsLogs };
