import apiEndpoints from "./utils/apiEndpoints";
import queryKeys from "./utils/queryKeys";
import useMutateApi from "./utils/useMutateApi";

export const usePostGoogleDriveFilesList = () => {
  return useMutateApi({
    method: "post",
    url: apiEndpoints.google.googleDriveFilesList,
    invalidateQueries: [queryKeys.google.googleDriveFilesList],
  });
};
