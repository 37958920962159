import React from "react";
import { Alert, Button, Progress, Space } from "antd";
import { useNavigate } from "react-router-dom";
import useSpendingLimit from "../../utils/useSpendingLimit";
import "./SpendingLimitWarning.scss";

export default function SpendingLimitWarning() {
  const navigate = useNavigate();
  const {
    isOverLimit,
    isNearLimit,
    isApproachingLimit,
    warningMessage,
    percentUsed,
    currentSpending,
    spendingLimit,
    formatCurrency,
  } = useSpendingLimit();

  if (!warningMessage || !spendingLimit) {
    return null;
  }

  const alertType = isOverLimit ? "error" : isNearLimit ? "warning" : "info";
  const goToSettings = () => {
    navigate("/settings?tab=account");
  };

  return (
    <div className="spending-limit-warning">
      <Alert
        type={alertType}
        message={
          <Space
            direction="vertical"
            style={{ width: "100%" }}
          >
            <div className="spending-alert-content">
              <span>{warningMessage}</span>
              <Button
                type="primary"
                size="small"
                onClick={goToSettings}
              >
                Adjust Limit
              </Button>
            </div>
            <div className="spending-progress">
              <Progress
                percent={Math.min(percentUsed, 100)}
                status={isOverLimit ? "exception" : isNearLimit ? "warning" : "active"}
                format={() => `${formatCurrency(currentSpending)} / ${formatCurrency(spendingLimit)}`}
              />
            </div>
          </Space>
        }
        banner
        showIcon
      />
    </div>
  );
}
