import React from "react";
import { Form, Flex, Select, InputNumber, DatePicker, TimePicker, Button, Checkbox, message } from "antd";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { useGetUserDetails } from "../../../../../../../apis/useUserApi";

dayjs.extend(utc);
dayjs.extend(timezone);
const { Option } = Select;

const ScheduleForm = ({ onCancel, onSave, schedule, isFormDisabled }) => {
  const [form] = Form.useForm();

  const { data: userDetails } = useGetUserDetails();

  const repeatUnit = Form.useWatch("repeatUnit", form);
  const endType = Form.useWatch("endType", form);

  const generateCronExpression = (values) => {
    const { repeatInterval, repeatUnit, daysOfWeek, time, endDate, occurrences } = values;

    const parsedTime = dayjs(time); // Ensure it's a valid Day.js object
    const minute = parsedTime.minute();
    const hour = parsedTime.hour();

    let cron = "";

    switch (repeatUnit) {
      case "day":
        cron = `${minute} ${hour} */${repeatInterval} * *`;
        break;

      case "week":
        const days = daysOfWeek?.map((day) => {
          const dayMapping = { S: 0, M: 1, T: 2, W: 3, T2: 4, F: 5, S2: 6 };
          return dayMapping[day];
        });
        cron = `${minute} ${hour} * * ${days.join(",")}`;
        break;

      case "month":
        cron = `${minute} ${hour} 1 */${repeatInterval} *`;
        break;

      case "year":
        cron = `${minute} ${hour} 1 1 *`; // Annual recurrence
        break;

      default:
        break;
    }

    return cron;
  };

  const formatUtcToTimezone = (time, tz) => {
    if (tz) {
      return dayjs(time).tz(tz);
    }

    return dayjs(time);
  };

  React.useEffect(() => {
    if (schedule) {
      form.setFieldsValue({
        ...schedule,
        time: schedule?.time ? formatUtcToTimezone(schedule.time, schedule.timezone) : null,
        endDate: schedule?.endDate ? formatUtcToTimezone(schedule.endDate, schedule.timezone) : null,
      });
    }
  }, [schedule, form]);

  const onFinish = (values) => {
    const cronExpression = generateCronExpression(values);
    // Create a dayjs object with the selected time and timezone
    const localTime = dayjs(values?.time).tz(values?.timezone);

    // Convert to UTC and format as ISO string
    const utcTime = localTime.utc().toISOString();

    let payload = {
      ...values,
      cronExpression,
      time: utcTime, // Convert back to string
      endDate: values.endDate ? dayjs(values.endDate).toISOString() : undefined,
    };

    onSave(payload);
  };

  return (
    <Form
      form={form}
      disabled={isFormDisabled}
      onFinish={(values) => {
        const formattedValues = {
          ...values,
          repeatInterval: values.repeatInterval || 1,
          daysOfWeek: values.daysOfWeek || [],
          time: values.time ? formatUtcToTimezone(values.time, values?.timezone) : dayjs().startOf("day"),
          endDate: values?.endDate ? formatUtcToTimezone(values?.endDate, values?.timezone) : undefined,
        };
        onFinish(formattedValues);
      }}
      layout="vertical"
      initialValues={{
        repeatInterval: 1,
        repeatUnit: "day",
        time: dayjs().startOf("day"),
        endType: "never",
        timezone: userDetails.currentTenant?.timezone || dayjs.tz.guess(),
      }}
    >
      <Flex
        gap={12}
        wrap
      >
        {/* Repeat Every */}
        <Form.Item
          label="Repeat every"
          name="repeatInterval"
          required
        >
          <InputNumber min={1} />
        </Form.Item>
        <Form.Item
          label=" "
          name="repeatUnit"
        >
          <Select style={{ width: 100 }}>
            <Option value="day">Day</Option>
            <Option value="week">Week</Option>
            <Option value="month">Month</Option>
            <Option value="year">Year</Option>
          </Select>
        </Form.Item>
        {/* Time of Day */}
        <Form.Item
          label="Time of Day"
          name="time"
          required
        >
          <TimePicker
            use12Hours
            format="h:mm a"
            // style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item
          label="Time Zone"
          name="timezone"
        >
          <Select
            showSearch
            placeholder="Select Timezone"
            optionFilterProp="children"
            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
          >
            {Intl.supportedValuesOf("timeZone").map((tz) => (
              <Select.Option
                key={tz}
                value={tz}
              >
                {tz}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Flex>

      {/* Repeat On (Weekly Options) */}
      {repeatUnit === "week" && (
        <Form.Item
          label="Repeat on"
          name="daysOfWeek"
        >
          <Checkbox.Group
            options={[
              { label: "S", value: "S" },
              { label: "M", value: "M" },
              { label: "T", value: "T" },
              { label: "W", value: "W" },
              { label: "T", value: "T2" },
              { label: "F", value: "F" },
              { label: "S", value: "S2" },
            ]}
            style={{ display: "flex", justifyContent: "space-between" }}
          />
        </Form.Item>
      )}

      <Flex gap={12}>
        {/* Ends */}
        <Form.Item
          label="Ends"
          name="endType"
        >
          <Select style={{ width: 100 }}>
            <Option value="never">Never</Option>
            <Option value="on">On</Option>
            <Option value="after">After</Option>
          </Select>
        </Form.Item>

        {endType === "on" && (
          <Form.Item
            name="endDate"
            label="End date"
            rules={[{ required: true, message: "Please select an end date" }]}
          >
            <DatePicker
              style={{ width: 140 }}
              placeholder="Select end date"
            />
          </Form.Item>
        )}

        {endType === "after" && (
          <Form.Item
            name="occurrences"
            label="Occurrences"
            initialValue={30}
            rules={[{ required: true, message: "Please specify occurrences" }]}
          >
            <InputNumber
              min={1}
              style={{ width: 140 }}
              placeholder="Enter number of occurrences"
            />
          </Form.Item>
        )}
      </Flex>

      {/* Submit Button */}
      {isFormDisabled !== true && (
        <Form.Item style={{ width: 200, margin: "auto" }}>
          <Flex
            align="center"
            gap={12}
            justify="end"
            style={{ marginTop: 36 }}
          >
            <Button
              type="default"
              htmlType="button"
              block
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              block
            >
              Save
            </Button>
          </Flex>
        </Form.Item>
      )}
    </Form>
  );
};

export default ScheduleForm;
