import React from "react";
import { shallow } from "zustand/shallow";
import { Flex, Typography, Avatar, Button, Form, Select, Input } from "antd";
import { debounce } from "lodash";
import { MODELS } from "../../../../../../../components/forms/forms/enum";
import { Handle, Position } from "@xyflow/react";
import FeatherIcon from "feather-icons-react";
import { putTenantWorkflow } from "../../../../../../../apis/useWorkflowApi";

import useWorkflowStore from "../../../../../../../context/stores/useWorkflowStore";
import useWorkflowActions from "../../../../../../../context/actions/useWorkflowActions";

import "./StartingNode.scss";

const { Text } = Typography;

const selector = (id) => (store) => ({
  nodes: store.nodes,
  edges: store.edges,
  selectedWorkflow: store.selectedWorkflow,
  workflowTitle: store.workflowTitle,
  setInput: (e) => {
    store.updateNode(id, { input: e.target.value });
  },
});

export default function StartingNode(props) {
  const { id, data, isConnectable } = props;
  const [form] = Form.useForm();
  const { onSelectAgent } = useWorkflowActions();
  const { setInput, nodes, edges, selectedWorkflow } = useWorkflowStore(selector(id), shallow);

  const foundNode = React.useMemo(() => {
    const foundNode = nodes.find((node) => node.id === id);
    if (foundNode) {
      form.setFieldValue("model", foundNode.data.model);
    }
    return foundNode;
  }, [JSON.stringify(nodes), id, form]);

  const handleInputDebounce = React.useCallback(
    debounce((e) => {
      setInput(e);

      const newNodes = nodes.map((node) => {
        if (node.id === id) {
          return {
            ...node,
            data: {
              ...node.data,
              input: e.target.value,
            },
          };
        }
        return node;
      });

      putTenantWorkflow(selectedWorkflow.id, {
        name: selectedWorkflow.name,
        description: "Test",
        type: "Sequential",
        config: JSON.stringify({
          nodes: newNodes,
          edges, // Include edges in the config
        }),
        isPrivate: true,
      });
    }, 500),
    [nodes, edges, selectedWorkflow, id]
  );

  React.useEffect(() => {
    form.validateFields();
  }, [id, form]);

  const classNames = ["starting-node-container"];
  if (data?.selected) classNames.push("node-selected");

  return (
    <div className={classNames.join(" ")}>
      <Flex vertical>
        <Flex
          className="starting-node-label-container"
          align="center"
          justify="space-between"
          gap={8}
        >
          <Flex
            align="center"
            gap={8}
          >
            <Avatar
              size={26}
              className="agent-avatar"
              icon={
                <FeatherIcon
                  icon={"user"}
                  size={16}
                />
              }
            />
            <Text strong>{foundNode?.data?.name}</Text>
          </Flex>
          <Button
            shape="circle"
            icon={
              <FeatherIcon
                icon={"edit-3"}
                size={16}
              />
            }
            onClick={() => onSelectAgent(foundNode?.data)}
          ></Button>
        </Flex>
        <Flex className="starting-node-inner-container">
          <Form
            name={data.name}
            layout="vertical"
            form={form}
            initialValues={data}
            style={{ width: "100%" }}
          >
            <Form.Item
              label="Model"
              name="model"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                options={MODELS}
                value={data.model}
                style={{ width: "100%" }}
              >
                {MODELS.map((item, i) => (
                  <Select.Option
                    key={i}
                    value={item.value}
                  >
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Input message"
              name="input"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input.TextArea
                value={data.prompt}
                rows={4}
                onChange={handleInputDebounce}
              />
            </Form.Item>
          </Form>
        </Flex>
      </Flex>

      <Handle
        type="target"
        position={Position.Left}
        style={{ background: "#555" }}
        onConnect={(params) => console.log("handle onConnect", params)}
        // isConnectable={isConnectable}
      />

      <Handle
        type="source"
        position={Position.Right}
        id="b"
        // style={{ bottom: 10, top: 'auto', background: '#555' }}
        isConnectable={isConnectable}
      />
    </div>
  );
}
