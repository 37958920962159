import React, { useState } from "react";
import { Button, theme } from "antd";
import useGoogleApi from "../../../../apis/useGoogleApi";
import FeatherIcon from "feather-icons-react";

const { useToken } = theme;

export default function EnableToolCellRenderer(params) {
  const { token } = useToken();
  const [loading, setLoading] = useState(false);
  const { signinWithGoogleTools, revokeGToken } = useGoogleApi();

  const handleRefreshToken = async () => {
    setLoading(true);
    try {
      await revokeGToken();
    } catch (error) {
      console.error("Error refreshing token:", error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  };

  const handleEnable = () => {
    if (params.data.id === "google-tools") {
      signinWithGoogleTools();
    }
  };

  if (params.value === true) {
    return (
      <div>
        <Button
          onClick={handleRefreshToken}
          icon={
            <FeatherIcon
              icon={"repeat"}
              size={12}
            />
          }
          loading={loading}
          type="primary"
          shape="circle"
        ></Button>
      </div>
    );
  }
  return (
    <Button
      onClick={handleEnable}
      type="primary"
    >
      Enable
    </Button>
  );
}
