import React from "react";
import dayjs from "dayjs";
import { Flex, Typography, Card } from "antd";
import { altFormatTimestamp } from "../../../utils/formatTimestamp";

import "./LogDetails.scss";

const { Meta } = Card;
const { Text } = Typography;

export default function LogDetails({ log }) {
  if (!log) return null;
  return (
    <Flex
      vertical
      gap={12}
      className="log-details-container"
    >
      <Flex
        gap={24}
        style={{ marginTop: 12 }}
      >
        <Flex gap={4}>
          <Text strong>Time:</Text>
          <Text>{altFormatTimestamp(dayjs(log.timestamp))}</Text>
        </Flex>
        <Flex gap={4}>
          <Text strong>Type:</Text>
          <Text>{log.logLevel}</Text>
        </Flex>
      </Flex>
      <Flex gap={4}>
        <Text strong>Message:</Text>
        <Text>{log.message}</Text>
      </Flex>
      <Flex
        gap={4}
        vertical
      >
        {log.metadata?.results?.map((result, index) => (
          <Card
            key={index}
            // title={result.agent}
          >
            <Meta
              title={result.agent}
              className="log-meta-container"
              description={`Created ${altFormatTimestamp(dayjs(result.created))}`}
              style={{ display: "flex", justifyContent: "space-between" }}
            />
            <Flex
              vertical
              gap={4}
              style={{ marginTop: 12 }}
            >
              <Text>
                <strong>Input:</strong> {result.input}
              </Text>
              <Text>
                <strong>Output:</strong> {result.output}
              </Text>
            </Flex>
          </Card>
        ))}
      </Flex>
    </Flex>
  );
}
