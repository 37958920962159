import React from "react";
import { useNavigate } from "react-router-dom";
import { useShallow } from "zustand/react/shallow";
import { kebabCase } from "lodash";
import { v4 } from "uuid";
import dayjs from "dayjs";
import uniqueWorkflowTitle from "../../utils/uniqueWorkflowTitle";
import {
  useGetTenantWorkflows,
  usePostTenantWorkflow,
  usePutTenantWorkflow,
  useDeleteTenantWorkflow,
  usePutWorkflowCache,
} from "../../apis/useWorkflowApi";
import { usePostSendMessage } from "../../apis/useChatApi";
import { useGetUserStripePlan } from "../../apis/useStripeApi";
import { useGetUserDetails } from "../../apis/useUserApi";
import { useGetJob } from "../../apis/useChatApi";
import { useGetTokenCounter } from "../../apis/useDashboardApi";
import useWorkflowStore from "../stores/useWorkflowStore";
import {
  useCreateWorkflowSchedule,
  useUpdateWorkflowSchedule,
  useDeleteWorkflowSchedule,
  useUpdateAllWorkflowSchedule,
} from "../../apis/useWorkflowSchedulesApi";
import { useGetWorkflowSchedules } from "../../apis/useWorkflowSchedulesApi";

// stores
import useChatStore from "../stores/useChatStore";

export default function useWorkflowActions() {
  const navigate = useNavigate();
  const { data: workflows } = useGetTenantWorkflows();
  const workflowsRef = React.useRef(workflows);
  workflowsRef.current = workflows;
  const postUserWorkflow = usePostTenantWorkflow();
  const putUserWorkflow = usePutTenantWorkflow();
  const deleteUserWorkflow = useDeleteTenantWorkflow();
  const postSendMessage = usePostSendMessage();
  const { data: currentUserStripePlan } = useGetUserStripePlan();
  const { refetch: refetchUserDetails } = useGetUserDetails();
  const { refetch: refetchTokenCounter } = useGetTokenCounter();
  const { getJob } = useGetJob();
  const putWorkflowCache = usePutWorkflowCache();

  const { setIsMessageLoading, setPayloadMessages, setChatLogs } = useChatStore();

  const {
    nodes,
    setNodes,
    setEdges,
    setEditAgent,
    setOpenAgentModal,
    setWorkflowTitle,
    selectedWorkflow,
    setSelectedWorkflow,
    setOpenPanel,
  } = useWorkflowStore(
    useShallow((state) => ({
      nodes: state.nodes,
      setEdges: state.setEdges,
      setNodes: state.setNodes,
      setEditAgent: state.setEditAgent,
      setOpenAgentModal: state.setOpenAgentModal,
      setWorkflowTitle: state.setWorkflowTitle,
      selectedWorkflow: state.selectedWorkflow,
      setSelectedWorkflow: state.setSelectedWorkflow,
      setOpenPanel: state.setOpenPanel,
    }))
  );

  const workflowId = selectedWorkflow?.id;
  const { data: schedules } = useGetWorkflowSchedules(workflowId);
  const createSchedule = useCreateWorkflowSchedule(workflowId);
  const updateSchedule = useUpdateWorkflowSchedule(workflowId);
  const deleteSchedule = useDeleteWorkflowSchedule(workflowId);
  const patchWorkflowSchedule = useUpdateAllWorkflowSchedule(workflowId);

  const onAddAgent = async (data) => {
    try {
      const id = `${kebabCase(data.name)}:${v4().split("-").pop()}`;
      const newNode = {
        id,
        key: v4(),
        type: "StartingNode",
        updatedAt: dayjs().toISOString(),
        position: {
          x: nodes.length + 20,
          y: 0,
        },
        data: {
          ...data,
          id,
        },
        origin: [0.5, 0.0],
      };
      const updatedNodes = [...nodes, newNode];
      await setNodes(updatedNodes);

      if (selectedWorkflow) {
        let config = JSON.parse(selectedWorkflow.config);
        config.nodes = updatedNodes;
        return await onUpdateWorkflow({
          ...selectedWorkflow,
          config: JSON.stringify(config),
          successMsg: "Successfully added agent 😁",
        });
      }
    } catch (error) {
      return error;
    }
  };

  const onUpdateAgent = async (data) => {
    const foundIndex = nodes.findIndex((node) => node.id === data.id);
    if (foundIndex !== -1) {
      const updatedNodes = [...nodes];
      updatedNodes[foundIndex].data = data;
      updatedNodes[foundIndex].updatedAt = dayjs().toISOString();
      updatedNodes[foundIndex].key = v4();
      await setNodes([...updatedNodes]);

      if (selectedWorkflow) {
        let config = JSON.parse(selectedWorkflow.config);
        config.nodes = updatedNodes;
        onUpdateWorkflow({
          ...selectedWorkflow,
          config: JSON.stringify(config),
          successMsg: "Successfully updated agent 👍",
        });
      }
    }
  };

  const onSelectAgent = (agent) => {
    setEditAgent(agent);
    setOpenAgentModal(true);
  };

  const getStartingAgents = () => {
    return nodes.filter((node) => isStartingNode(node.id));
  };

  const onCompleteJob = (results) => {
    if (Array.isArray(results)) {
      setChatLogs(results);
      refetchTokenCounter();
      if (currentUserStripePlan?.name === "Free") {
        refetchUserDetails();
      }
    }
    setIsMessageLoading(false);
  };

  const onSendMessage = () => {
    setOpenPanel("logs");
    setIsMessageLoading(true);
    setChatLogs([]);

    const formatPayloadMessages = () => {
      const messages = {};
      nodes.forEach((node) => {
        if (isStartingNode(node.id)) {
          messages[node.id] = node.data.input;
        }
      });

      setPayloadMessages(messages);
      return messages;
    };

    postSendMessage(
      {
        body: {
          message: formatPayloadMessages(),
          workflowId: selectedWorkflow.id,
        },
      },
      {
        onSuccess: async (resp) => {
          onPollJob(resp.data.jobId);
        },
        onError: (error) => {
          console.log(error);
          setIsMessageLoading(false);
        },
      }
    );
  };

  const onPollJob = async (jobId) => {
    await new Promise((r) => setTimeout(r, 1000));

    try {
      const resp = await getJob(jobId);
      const status = resp?.data?.status;
      const results = resp?.data?.results;

      if (status === "in_progress") {
        if (Array.isArray(results)) setChatLogs(results);
        onPollJob(jobId);
      } else if (status === "completed") {
        onCompleteJob(results);
      } else if (status === "error") {
        setIsMessageLoading(false);
      }
    } catch (error) {
      setIsMessageLoading(false);
    }
  };

  const onCreateWorkflow = ({
    name,
    description,
    config = JSON.stringify({ nodes: [], edges: [] }),
    successMsg = "Successfully created engine",
  }) => {
    const workflowNames = workflowsRef.current?.privateWorkflows?.map((item) => item.name) || [];
    const uniqueTitle = uniqueWorkflowTitle(name, workflowNames);

    postUserWorkflow(
      {
        body: {
          name: uniqueTitle,
          description,
          type: "Sequential",
          config,
          isPrivate: true,
        },
        successMsg,
      },
      {
        onSuccess: (resp) => {
          setSelectedWorkflow(resp.data);
          navigate(`/engines/${resp.data.id}`);
        },
      }
    );
  };

  const onUpdateWorkflow = (
    { id, name, description, is_active_workflow = false, config = "", successMsg = "Successfully updated engine" },
    callbacks = {}
  ) => {
    putUserWorkflow(
      {
        id,
        body: {
          name,
          description,
          type: "Sequential",
          config,
          isPrivate: true,
          is_active: is_active_workflow,
        },
        successMsg,
      },
      {
        onSuccess: (data) => {
          putWorkflowCache({ body: { workflowId: id } });

          // Handle schedules if workflow is deactivated
          if (
            !is_active_workflow &&
            schedules?.schedules?.length > 0 &&
            schedules?.schedules.some((schedule) => schedule.isActive)
          ) {
            patchWorkflowSchedule({ body: { workflowId: id, isActive: false } });
          }

          // Call success callback if provided
          if (typeof callbacks?.onSuccess === "function") {
            callbacks.onSuccess(data);
          }
        },
        onError: (error) => {
          // Call error callback if provided
          if (typeof callbacks?.onError === "function") {
            callbacks.onError(error);
          }
        },
      }
    );
  };

  const onDeleteWorkflow = (id) => {
    deleteUserWorkflow(
      {
        params: {
          ids: [id],
        },
      },
      {
        onSuccess: () => {
          putWorkflowCache({ body: { workflowId: id } });
          setWorkflowTitle("(Untitled)");
          setEdges([]);
          setNodes([]);
          setSelectedWorkflow(null);
        },
      }
    );
  };

  /**
   * Checks if a node is a starting node in a React Flow configuration.
   *
   * A node is considered a starting node if:
   * 1. The `startingNode` property in the node's `data` is `true`.
   * 2. The node has no incoming edges.
   *
   * @param {string} nodeId - The ID of the node to check.
   * @returns {boolean} - True if the node is a starting node, false otherwise.
   */
  function isStartingNode(nodeId) {
    try {
      // Parse the JSON string to an object
      const config = JSON.parse(selectedWorkflow.config);

      // Validate the input structure
      if (!config.nodes || !config.edges) {
        throw new Error("Invalid configuration: Missing 'nodes' or 'edges' property.");
      }

      const { nodes, edges } = config;

      // Find the node with the given ID
      const node = nodes.find((n) => n.id === nodeId);
      if (!node) return false; // Return false if the node is not found

      // Check if the node has any incoming edges
      const hasIncomingEdges = edges.some((edge) => edge.target === nodeId);

      // Return true if both conditions for starting node are met
      return !hasIncomingEdges;
    } catch (error) {
      console.error("Error processing the React Flow configuration:", error.message);
      return false;
    }
  }

  const onCreateWorkflowSchedule = (schedule) => {
    try {
      const newSchedule = createSchedule({
        body: {
          ...schedule,
        },
      });
      return newSchedule;
    } catch (error) {
      console.error("Error creating workflow schedule:", error);
      throw error;
    }
  };

  const onUpdateWorkflowSchedule = (scheduleId, scheduleData, callbacks = {}) => {
    try {
      const updatedSchedule = updateSchedule(
        {
          id: scheduleId,
          body: scheduleData,
        },
        {
          onSuccess: (data) => {
            if (callbacks.onSuccess) {
              callbacks.onSuccess(data);
            }
          },
          onError: (error) => {
            if (callbacks.onError) {
              callbacks.onError(error);
            }
          },
        }
      );
      return updatedSchedule;
    } catch (error) {
      console.error("Error updating workflow schedule:", error);
      if (callbacks.onError) {
        callbacks.onError(error);
      }
      throw error;
    }
  };

  const onDeleteWorkflowSchedule = async (scheduleId, callbacks = {}) => {
    try {
      await deleteSchedule(
        { id: scheduleId },
        {
          onSuccess: (data) => {
            if (callbacks.onSuccess) {
              callbacks.onSuccess(data);
            }
          },
          onError: (error) => {
            if (callbacks.onError) {
              callbacks.onError(error);
            }
          },
        }
      );
      return true;
    } catch (error) {
      console.error("Error deleting workflow schedule:", error);
      if (callbacks.onError) {
        callbacks.onError(error);
      }
      throw error;
    }
  };

  return {
    onAddAgent,
    onUpdateAgent,
    onSelectAgent,
    onCreateWorkflow,
    onUpdateWorkflow,
    onDeleteWorkflow,
    isStartingNode,
    onSendMessage,
    onCreateWorkflowSchedule,
    onUpdateWorkflowSchedule,
    onDeleteWorkflowSchedule,
    getStartingAgents,
  };
}
