import React from "react";
import dayjs from "dayjs";
import { Card, Col, Grid, Row, Statistic, theme, Typography } from "antd";
import { useGetUserDetails } from "../apis/useUserApi";
import { useGetTokenCounter } from "../apis/useDashboardApi";
import { useGetUserStripePlan } from "../apis/useStripeApi";
import useSpendingLimit from "./useSpendingLimit";

const { useToken } = theme;
const { useBreakpoint } = Grid;
const { Text } = Typography;

export default function useTokenUsage() {
  const { data: user } = useGetUserDetails();
  const { data: tokenCounter } = useGetTokenCounter();
  const { data: userStripePlan } = useGetUserStripePlan();
  const { currentSpending, formatCurrency } = useSpendingLimit();

  const { token } = useToken();
  const screens = useBreakpoint();

  const styles = {
    card: {
      position: "relative",
    },
    container: {
      // margin: "0 auto",
      maxWidth: screens.lg ? token.screenXL : token.screenSM,
      padding: screens.md ? `${token.paddingLG}px` : `${token.padding}px`,
    },
    section: {
      backgroundColor: token.colorBgContainer,
      // padding: `${token.sizeXXL}px 0px`
    },
  };

  const renderTokenUsageCards = () => {
    const cards = [];

    // Add total spending card at the top
    if (tokenCounter?.tokenUsage && Array.isArray(tokenCounter.tokenUsage)) {
      cards.push(
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={6}
          xl={8}
          key="total-spending"
        >
          <Card style={{ body: styles.card }}>
            <Statistic
              title={`Total Monthly Spending. ${dayjs
                .unix(tokenCounter.tokenUsage[0].period.start)
                .format("DD MMM")} - ${dayjs
                .unix(tokenCounter.tokenUsage[0].period.end)
                .format("DD MMM")}`}
              value={formatCurrency(currentSpending)}
              precision={2}
            />
          </Card>
        </Col>
      );
    }

    if (userStripePlan?.name === "Free" || userStripePlan?.name === "GAS Engine Premium") {
      if (!user?.currentTenant?.tokenUsage?.models) return cards;

      Object.keys(user.currentTenant.tokenUsage.models).forEach((key, i) => {
        const item = user.currentTenant.tokenUsage.models[key];

        cards.push(
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={6}
            xl={6}
            key={`${i}-input`}
          >
            <Card style={{ body: styles.card }}>
              <Statistic
                title={`${item.label} - Input Tokens`}
                value={item.inputTokens}
              />
              {item?.period && (
                <Text type="secondary">
                  {dayjs.unix(item.period.start).format("DD MMM")} -{dayjs.unix(item.period.end).format("DD MMM")}
                </Text>
              )}
            </Card>
          </Col>
        );

        cards.push(
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={6}
            xl={6}
            key={`${i}-output`}
          >
            <Card style={{ body: styles.card }}>
              <Statistic
                title={`${item.label} - Output Tokens`}
                value={item.outputTokens}
              />
              {item?.period && (
                <Text type="secondary">
                  {dayjs.unix(item.period.start).format("DD MMM")} -{dayjs.unix(item.period.end).format("DD MMM")}
                </Text>
              )}
            </Card>
          </Col>
        );
      });

      return cards;
    }

    // For non-free plans
    if (tokenCounter?.tokenUsage) {
      tokenCounter.tokenUsage.forEach((item, i) => {
        cards.push(
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={6}
            xl={6}
            key={`${i}-input`}
          >
            <Card style={{ body: styles.card }}>
              <Statistic
                title={`${item.nickname}`}
                value={item.quantity}
              />
              {item?.period && (
                <Text type="secondary">
                  {dayjs.unix(item.period.start).format("DD MMM")} -{dayjs.unix(item.period.end).format("DD MMM")}
                </Text>
              )}
            </Card>
          </Col>
        );
      });
    }

    return cards;
  };

  const isOverFreeLimit = React.useMemo(() => {
    if (userStripePlan?.name === "Free" || userStripePlan?.name === "GAS Engine Premium") {
      if (user?.currentTenant?.tokenUsage?.models) {
        const { models } = user.currentTenant.tokenUsage;
        for (let key in models) {
          if (models[key].inputTokens > 100000 || models[key].outputTokens > 100000) return true;
        }
      }
    }
    return false;
  }, [user?.currentTenant?.tokenUsage, userStripePlan?.name]);

  return {
    renderTokenUsageCards,
    isOverFreeLimit,
  };
}
