import React from "react";
import { Flex, Input, Space, Button, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { usePostTenant } from "../../apis/useTenantApi";
import { APP_STATE } from "../../constants/auth";
import useAppStore from "../../context/stores/useAppStore";
import "./CreateWorkspace.scss";

const { Title } = Typography;

export default function CreateWorkspace() {
  const [workspaceName, setWorkspaceName] = React.useState("");
  const [isButtonLoading, setIsButtonLoading] = React.useState(false);
  const { setAppState } = useAppStore();
  const postTenant = usePostTenant();
  const navigate = useNavigate();

  const onCreateTenant = () => {
    setIsButtonLoading(true);
    postTenant(
      { body: { name: workspaceName } },
      {
        onSuccess: () => {
          setAppState(APP_STATE.authenticated);
          navigate("/");
        },
        onSettled: () => {
          setIsButtonLoading(false);
        },
      }
    );
  };

  return (
    <Flex
      className="create-workspace-container"
      justify="center"
      align="center"
      vertical={true}
    >
      <Title
        level={3}
        style={{ marginBottom: 8 }}
      >
        Create a new workspace
      </Title>
      <Space.Compact>
        <Input
          size="large"
          style={{ width: 250 }}
          placeholder={"Workspace"}
          onChange={(e) => setWorkspaceName(e.target.value)}
        />
        <Button
          type="primary"
          size="large"
          icon={
            <FeatherIcon
              icon={"play"}
              size={14}
            />
          }
          loading={isButtonLoading}
          onClick={onCreateTenant}
        />
      </Space.Compact>
    </Flex>
  );
}
