import React from "react";
import { useGetUserDetails } from "../../../../apis/useUserApi";
import Grid from "../../../../components/Grid/Grid";
import { Typography } from "antd";
import "./Tools.scss";

const { Text } = Typography;
const toolsRowData = [
  {
    id: "google-tools",
    name: "Google",
    description:
      "Streamline your workflow with Gmail and Google Drive integration. Create email drafts in Gmail, plus read and create Google Drive files. Edit capabilities are limited to files created by the GAS engine.",
    isActive: false,
  },
];

export default function Tools() {
  const { data: userDetails } = useGetUserDetails();
  const [rowData, setRowData] = React.useState(toolsRowData);

  const gridOptions = React.useMemo(() => {
    return {
      columnDefs: [
        { headerName: "Tool", field: "name", width: 90 },
        { headerName: "Description", field: "description", flex: 2 },
        {
          headerName: "Status",
          field: "isActive",
          width: 90,
          cellRenderer: (params) =>
            params.value ? <Text type="success">Enabled</Text> : <Text type="danger">Disabled</Text>,
        },
        {
          headerName: "Actions",
          field: "isActive",
          cellRenderer: "EnableToolCellRenderer",
          width: 90,
        },
      ],
    };
  }, []);

  React.useEffect(() => {
    if (userDetails) {
      const rowData = toolsRowData.map((tool) => {
        if (tool.id === "google-tools") {
          return {
            ...tool,
            isActive: userDetails?.currentTenant?.gToolsEnabled,
          };
        }

        return tool;
      });
      setRowData(rowData);
    }
  }, [userDetails]);

  return (
    <Grid
      rowData={rowData}
      gridOptions={gridOptions}
    />
  );
}
