import { create } from "zustand";

const init = {
  payloadMessages: {},
  chatHistory: [],
  isMessageLoading: false,
  chatLogs: [],
};

const useChatStore = create((set) => ({
  ...init,
  setPayloadMessages: (payloadMessages) => set({ payloadMessages }),
  setChatHistory: (chatHistory) => set({ chatHistory }),
  setIsMessageLoading: (isMessageLoading) => set({ isMessageLoading }),
  setChatLogs: (chatLogs) => set({ chatLogs }),
}));

export default useChatStore;
