import React, { useEffect, useState } from "react";
import { useShallow } from "zustand/react/shallow";
import { useParams } from "react-router-dom";

// Components
import Panel from "../Panel/Panel";
import { Button, Tooltip, Flex, message, Alert, App, Spin } from "antd";
import ScheduleForm from "./components/ScheduleForm/ScheduleForm";
import Modal from "../../../../../components/Modal/Modal";
import FeatherIcon from "feather-icons-react";
import ScheduleCard from "./components/ScheduleCard/ScheduleCard";
import useWorkflowStore from "../../../../../context/stores/useWorkflowStore";

// API Hooks
import useWorkflowActions from "../../../../../context/actions/useWorkflowActions";
import { useGetWorkflowSchedules } from "../../../../../apis/useWorkflowSchedulesApi";
import useSpendingLimit from "../../../../../utils/useSpendingLimit";
import "./Schedule.scss";

export default function Schedule() {
  const { workflowId } = useParams();
  const { modal } = App.useApp();
  const { data: schedules, isLoading: isSchedulesLoading, refetch: refetchSchedules } = useGetWorkflowSchedules(workflowId);
  const { isOverLimit } = useSpendingLimit();
  const schedulesArr = schedules?.schedules || [];
  const [selectedScheduleIndex, setSelectedScheduleIndex] = useState(null);
  const [openScheduleForm, setOpenScheduleForm] = useState(false);
  const [isCreatingSchedule, setIsCreatingSchedule] = useState(false);
  const [isDeletingSchedule, setIsDeletingSchedule] = useState(false);
  const { onCreateWorkflowSchedule, onUpdateWorkflowSchedule, onDeleteWorkflowSchedule, getStartingAgents } =
    useWorkflowActions();

  const { selectedWorkflow } = useWorkflowStore(
    useShallow((state) => ({
      selectedWorkflow: state.selectedWorkflow,
    }))
  );

  const onSave = async (schedule) => {
    if (isOverLimit) {
      modal.error({
        title: 'Monthly Spending Limit Reached',
        content: (
          <div>
            <p>You have reached your monthly spending limit. Schedules cannot be created or updated until you:</p>
            <ul>
              <li>Increase your monthly spending limit in Settings</li>
              <li>Wait for the next billing cycle</li>
            </ul>
          </div>
        ),
        okText: 'Go to Settings',
        onOk: () => {
          window.location.href = '/settings?tab=account';
        },
        cancelText: 'Cancel',
      });
      return;
    }

    setIsCreatingSchedule(true);
    try {
      if (selectedScheduleIndex !== null) {
        // Update existing schedule
        await onUpdateWorkflowSchedule(schedulesArr?.[selectedScheduleIndex]?.id, schedule);
        message.success("Schedule updated successfully ✅");
      } else {
        // Create new schedule
        await onCreateWorkflowSchedule({
          ...schedule,
          workflowId: selectedWorkflow.id,
        });
        message.success("Schedule created successfully ✅");
      }
      setOpenScheduleForm(false);
      setSelectedScheduleIndex(null);
      refetchSchedules();
    } catch (error) {
      message.error("Failed to save schedule ❌");
    } finally {
      setIsCreatingSchedule(false);
    }
  };

  const onToggleScheduleStatus = (scheduleId, isActive, callbacks = {}) => {
    if (isActive && isOverLimit) {
      modal.error({
        title: 'Monthly Spending Limit Reached',
        content: (
          <div>
            <p>You have reached your monthly spending limit. Schedules cannot be activated until you:</p>
            <ul>
              <li>Increase your monthly spending limit in Settings</li>
              <li>Wait for the next billing cycle</li>
            </ul>
          </div>
        ),
        okText: 'Go to Settings',
        onOk: () => {
          window.location.href = '/settings?tab=account';
        },
        cancelText: 'Cancel',
      });
      if (callbacks.onError) callbacks.onError();
      return;
    }

    try {
      message.loading({
        content: `${isActive ? 'Activating' : 'Deactivating'} schedule...`,
        key: 'scheduleStatus',
        duration: 0,
      });
      
      onUpdateWorkflowSchedule(scheduleId, { isActive }, {
        onSuccess: () => {
          message.success({
            content: `Schedule ${isActive ? 'activated' : 'deactivated'} successfully ✅`,
            key: 'scheduleStatus',
            duration: 2,
          });
          refetchSchedules();
          if (callbacks.onSuccess) callbacks.onSuccess();
        },
        onError: (error) => {
          message.error({
            content: `Failed to ${isActive ? 'activate' : 'deactivate'} schedule ❌`,
            key: 'scheduleStatus',
            duration: 2,
          });
          console.error('Schedule status update error:', error);
          if (callbacks.onError) callbacks.onError();
        }
      });
    } catch (error) {
      message.error({
        content: `Failed to update schedule status ❌`,
        key: 'scheduleStatus',
        duration: 2,
      });
      console.error('Schedule status update error:', error);
      if (callbacks.onError) callbacks.onError();
    }
  };

  const onEditSchedule = (index) => {
    setOpenScheduleForm(true);
    setSelectedScheduleIndex(index);
  };

  const onDeleteSchedule = (index) => {
    try {
      const scheduleId = schedulesArr[index].id;
      
      setIsDeletingSchedule(true);
      message.loading({
        content: 'Deleting schedule...',
        key: 'deleteSchedule',
        duration: 0,
      });
      
      onDeleteWorkflowSchedule(scheduleId, {
        onSuccess: () => {
          message.success({
            content: 'Schedule deleted successfully ✅',
            key: 'deleteSchedule',
            duration: 2,
          });
          refetchSchedules();
          setIsDeletingSchedule(false);
        },
        onError: (error) => {
          message.error({
            content: 'Failed to delete schedule ❌',
            key: 'deleteSchedule',
            duration: 2,
          });
          console.error('Schedule deletion error:', error);
          setIsDeletingSchedule(false);
        }
      });
    } catch (error) {
      message.error({
        content: 'Failed to delete schedule ❌',
        key: 'deleteSchedule',
        duration: 2,
      });
      console.error('Schedule deletion error:', error);
      setIsDeletingSchedule(false);
    }
  };

  const onCloseScheduleForm = () => {
    setOpenScheduleForm(false);
    setSelectedScheduleIndex(null);
  };

  const isMissingInputMessage = () => {
    const nodes = getStartingAgents();
    return nodes.some((node) => !node.data.input);
  };

  const handleAddSchedule = () => {
    if (isOverLimit) {
      modal.error({
        title: 'Monthly Spending Limit Reached',
        content: (
          <div>
            <p>You have reached your monthly spending limit. New schedules cannot be created until you:</p>
            <ul>
              <li>Increase your monthly spending limit in Settings</li>
              <li>Wait for the next billing cycle</li>
            </ul>
          </div>
        ),
        okText: 'Go to Settings',
        onOk: () => {
          window.location.href = '/settings?tab=account';
        },
        cancelText: 'Cancel',
      });
    } else {
      setOpenScheduleForm(true);
    }
  };

  return (
    <Panel
      className="schedule-panel-container"
      title="Schedule engine"
      extra={[
        <Tooltip
          title={isOverLimit ? "Monthly spending limit reached" : "Schedule engine run"}
          key="add-schedule-btn"
        >
          <Button
            icon={
              <FeatherIcon
                icon="plus"
                size={10}
              />
            }
            shape="circle"
            type="primary"
            onClick={handleAddSchedule}
            disabled={isOverLimit}
          />
        </Tooltip>,
      ]}
    >
      <Flex
        vertical
        gap={12}
        style={{ marginTop: 24 }}
      >
        {isMissingInputMessage() && (
          <Alert
            message="Input messages are required to schedule engine runs."
            type="error"
            showIcon
          />
        )}

        {isOverLimit && (
          <Alert
            message="Monthly spending limit reached. Scheduled runs are paused until the limit is increased or reset."
            type="error"
            showIcon
          />
        )}

        {isSchedulesLoading ? (
          <div style={{ textAlign: 'center', padding: '20px' }}>
            <Spin tip="Loading schedules..." />
          </div>
        ) : schedulesArr.length === 0 ? (
          <Alert
            message="No schedules found. Create a schedule to run this workflow automatically."
            type="info"
            showIcon
          />
        ) : (
          schedulesArr.map((schedule, i) => (
            <ScheduleCard
              key={schedule.id}
              index={i}
              schedule={schedule}
              onEditSchedule={onEditSchedule}
              onDeleteSchedule={onDeleteSchedule}
              onToggleActive={onToggleScheduleStatus}
              disableActivation={isOverLimit}
            />
          ))
        )}
      </Flex>
      <Modal
        title="Schedule engine run"
        open={openScheduleForm}
        setOpen={onCloseScheduleForm}
        footer={[]}
        width={580}
      >
        <Spin spinning={isCreatingSchedule} tip="Saving schedule...">
          <ScheduleForm
            onCancel={() => onCloseScheduleForm(false)}
            onSave={onSave}
            schedule={schedulesArr?.[selectedScheduleIndex]}
          />
        </Spin>
      </Modal>
    </Panel>
  );
}
