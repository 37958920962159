import React from "react";
import Modal from "../../../Modal/Modal";
import { debounce } from "lodash";
import { Flex, Form, Select, Input, Slider, Spin, Collapse, theme } from "antd";
import { MODELS, TOOLS, AGENT_TYPES } from "../enum";
import { CaretRightOutlined } from "@ant-design/icons";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Tools from "../../../../containers/Settings/components/Tools/Tools";
import EnhancePromptButton from './EnhancePromptButton';

import useWorkflowActions from "../../../../context/actions/useWorkflowActions";
import useWorkflowStore from "../../../../context/stores/useWorkflowStore";
import { useGetUserDetails } from "../../../../apis/useUserApi";
import { usePostGoogleDriveFilesList } from "../../../../apis/useGoogleToolsApi";

import "./AgentFormModal.scss";

const { Option } = Select;

export default function AgentForm({ open, setOpen }) {
  const { data: userDetails } = useGetUserDetails();
  const postGoogleDriveFilesList = usePostGoogleDriveFilesList();
  const [form] = Form.useForm();
  const { token } = theme.useToken();
  const tools = Form.useWatch("tools", form);

  const { onAddAgent, onUpdateAgent } = useWorkflowActions();
  const { editAgent, setEditAgent } = useWorkflowStore();

  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = React.useState([]);

  // Debounced search function (to reduce API calls)
  const fetchFiles = React.useCallback(
    debounce(async (query) => {
      if (!query) return;
      setLoading(true);

      postGoogleDriveFilesList(
        { body: { query } },
        {
          onSuccess: ({ data }) => {
            setOptions(
              data.files.map((file) => ({
                label: file.name,
                value: file.webViewLink,
              }))
            );
          },
          onError: (error) => {
            console.error("Error fetching files:", error);
          },
          onSettled: () => {
            setLoading(false);
          },
        }
      );
    }, 500), // Debounce delay: 500ms
    []
  );

  const onSubmit = (values) => {
    let agent = { ...values };

    let payload = {
      ...agent,
    };

    let docId = values.doc;
    let docFound = {};
    if (docId) {
      docFound = options.find((option) => option.value === docId);
      if (docFound) payload.doc = docFound;
    }

    if (editAgent?.id) {
      payload = {
        ...editAgent,
        ...payload,
        id: editAgent.id,
      };
      onUpdateAgent(payload);
    } else {
      onAddAgent(payload);
    }
    onClose();
  };

  React.useEffect(() => {
    if (editAgent?.id) {
      form.setFieldsValue(editAgent);
    }
  }, [editAgent, open, form]);

  const onClose = () => {
    setOpen(false);
    setEditAgent(null);
    form.resetFields();
  };

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID_TOOLS}>
      <Modal
        className="agent-form-modal-container"
        title={editAgent?.id ? "Update Agent" : "Add Agent"}
        open={open}
        setOpen={onClose}
        okText={editAgent?.id ? "Update Agent" : "Add Agent"}
        onOk={form.submit}
        width={720}
        getContainer={() => document.getElementById("workflow-content-id")}
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={onSubmit}
          initialValues={{
            temperature: 0,
            model: "claude-3-haiku-20240307",
            type: "general",
            tools: [],
            doc: editAgent?.doc,
          }}
        >
          <Flex gap={12}>
            <Form.Item
              label="Name"
              name="name"
              style={{ flex: 2 }}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Temperature"
              name="temperature"
              style={{ flex: 2 }}
            >
              <Slider
                min={0}
                max={1}
                step={0.1}
              />
            </Form.Item>
          </Flex>

          <Flex gap={12}>
            <Form.Item
              label="Model"
              name="model"
              style={{ flex: 2 }}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select options={MODELS}>
                {MODELS.map((item, i) => (
                  <Option
                    key={i}
                    value={item.value}
                  >
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Type"
              name="type"
              style={{ flex: 2 }}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select options={AGENT_TYPES}>
                {AGENT_TYPES.map((item, i) => (
                  <Option
                    key={i}
                    value={item.value}
                  >
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Flex>

          <Form.Item
            label={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span>Prompt</span>
                <EnhancePromptButton
                  form={form}
                  model={form.getFieldValue('model')}
                  agentName={form.getFieldValue('name')}
                  temperature={form.getFieldValue('temperature')}
                  tools={form.getFieldValue('tools')}
                  onEnhancedPrompt={(enhancedPrompt) => {
                    form.setFieldsValue({ prompt: enhancedPrompt });
                  }}
                />
              </div>
            }
            name="prompt"
            rules={[{ required: true, message: 'Please enter a prompt' }]}
          >
            <Input.TextArea
              rows={4}
              placeholder="Enter your prompt here..."
              style={{ resize: 'none' }}
            />
          </Form.Item>

          <Form.Item
            label="Tools"
            name="tools"
            tooltip={
              userDetails?.currentTenant?.gToolsEnabled
                ? "Only 1 tool per agent is allowed"
                : "Only 1 tool per agent is allowed\n\nNote: Enable Google tools in Settings > Tools"
            }
          >
            <Select
              mode="multiple"
              maxCount={1}
              options={TOOLS.map((option) => {
                if (
                  userDetails?.currentTenant?.gToolsEnabled !== true &&
                  (option.label.includes("Gmail") || option.label.includes("Google"))
                ) {
                  return {
                    ...option,
                    disabled: true,
                  };
                }

                return option;
              })}
            />
          </Form.Item>
          {tools?.find((toolId) => toolId === 5 || toolId === 6) && (
            <Form.Item
              name={"doc"}
              label={"Google Drive File"}
            >
              <Select
                showSearch
                allowClear
                placeholder="Search Google Drive files..."
                filterOption={false} // Prevents built-in filtering
                onSearch={fetchFiles} // Calls debounced function
                notFoundContent={loading ? <Spin size="small" /> : "File not found, please check the file name."}
                style={{ width: "100%" }}
              >
                {options.map((file) => (
                  <Option
                    key={file.value}
                    value={file.value}
                  >
                    {file.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}

          <Collapse
            bordered={false}
            defaultActiveKey={null}
            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
            style={{ background: token.colorBgContainer }}
            items={[
              {
                key: "1",
                label: "Tool properties",
                children: <Tools />,
                // style: panelStyle,
              },
            ]}
          />
        </Form>
      </Modal>
    </GoogleOAuthProvider>
  );
}
