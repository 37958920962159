import { useQueryClient, useMutation } from "@tanstack/react-query";
import axios from "axios";
import { App } from "antd";
import useCookies from "./useCookies";
import { ACCESS_TOKEN_KEY, APP_STATE } from "../../constants/auth";
import useAppStore from "../../context/stores/useAppStore";

const useMutateApi = ({ method, url, invalidateQueries = [], successMsg: successMsgProp, infoMsg, options = {} }) => {
  const { getCookie, deleteCookie } = useCookies();
  const queryClient = useQueryClient();
  const { message } = App.useApp();
  const { setAppState } = useAppStore();
  let successMsg = successMsgProp;

  const { mutate } = useMutation({
    mutationFn: async ({ id, body, headers = {}, params = {}, successMsg: successMsgProp }) => {
      let urlPath = url;
      if (id) urlPath = `${url}/${id}`;
      successMsg = successMsgProp;

      let axiosParams = {
        method,
        url: urlPath,
        headers: {
          Authorization: `Bearer ${getCookie(ACCESS_TOKEN_KEY)}`,
          ...headers,
        },
        options,
        params,
      };
      if (body) axiosParams.data = body;

      try {
        return await axios(axiosParams);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          console.warn("Session expired. Logging out...");
          deleteCookie(ACCESS_TOKEN_KEY);
          setAppState(APP_STATE.unauthenticated);
          message.warning("Session expired. Please log in again.");
        }
        throw error;
      }
    },

    onSuccess: (data) => {
      invalidateQueries.forEach((key) => {
        queryClient.invalidateQueries({ queryKey: key });
      });

      if (successMsg) {
        message.success(successMsg);
      }
      if (infoMsg) {
        message.info(infoMsg);
      }

      return data;
    },
    onError: (err) => console.log("useMutateApi err", { err }),
  });

  return mutate;
};

export default useMutateApi;
